import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, useState } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import { useLayer } from 'react-laag'

interface CheckboxDropdownProps {
  label: string
  options: {
    value: string
    label: string
    checked: boolean
  }[]
  classes?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

const CheckboxDropdown: FC<CheckboxDropdownProps> = ({
  classes,
  label,
  options,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { renderLayer, layerProps, triggerProps } = useLayer({
    isOpen,
    placement: 'bottom-end',
    triggerOffset: 8,
    containerOffset: 16,
    onOutsideClick: () => setIsOpen(false),
  })

  return (
    <div className={classes}>
      <div className='hidden sm:flex sm:items-baseline sm:space-x-8 relative'>
        <div className='relative inline-block text-left'>
          <div>
            <button
              {...triggerProps}
              onClick={() => setIsOpen(!isOpen)}
              className='group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900'
            >
              <span>{label}</span>
              {options.length ? (
                <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums'>
                  {options.filter((option) => option.checked).length}
                </span>
              ) : null}
              <IoChevronDown
                className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                aria-hidden='true'
              />
            </button>
          </div>
        </div>
      </div>

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.1 } }}
              exit={{ opacity: 0, transition: { duration: 0.1 } }}
              {...layerProps}
            >
              <div className='origin-top-right z-10 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='space-y-4'>
                  {options.map((option) => (
                    <div key={option.value} className='flex items-center'>
                      <input
                        id={option.value}
                        onChange={(e) => onChange(e, option.label)}
                        defaultChecked={option.checked}
                        type='checkbox'
                        className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500 cursor-pointer'
                      />
                      <label
                        htmlFor={`${option.value}`}
                        className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap cursor-pointer'
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </div>
  )
}

export default CheckboxDropdown
