import { useQuery } from '@apollo/client'
import Tooltip from 'base-components/Tooltip'
import {
  WorkspaceActiveWorkforce,
  WorkspaceActiveWorkforceVariables,
} from 'generatedTypes'
import { WorkspaceActiveWorkforceQuery } from 'graphql/queries/Workspace'

import { useWorkspace } from 'providers/WorkspaceProvider'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface CurrentWorkforceCardsProps {}

const CurrentWorkforceCards: FC<CurrentWorkforceCardsProps> = () => {
  const { selectedWorkspace } = useWorkspace()
  const { t } = useTranslation()

  const workdaysResponse = useQuery<
    WorkspaceActiveWorkforce,
    WorkspaceActiveWorkforceVariables
  >(WorkspaceActiveWorkforceQuery, {
    variables: {
      data: {
        workspaceId: selectedWorkspace?.id ?? '',
      },
    },
    skip: !selectedWorkspace?.id,
  })

  const workdays = workdaysResponse.data?.workspaceActiveWorkforce ?? []

  return (
    <ul className='flex flex-wrap gap-5 pb-0.5 px-0.5 '>
      {workdaysResponse.loading && <Skeleton />}

      {workdays.map(({ id: externalOrderId, activeWorkers, name }) => (
        <li
          key={externalOrderId}
          className='bg-white hover:bg-gray-50 rounded-lg shadow h-full w-40 inline-block whitespace-normal'
        >
          <Tooltip text={name}>
            <div className='flex-center text-center flex-col p-4 space-y-2'>
              <h4 className='text-xs overflow-hidden text-ellipsis whitespace-nowrap w-full'>
                {name}
              </h4>
              <p className='font-bold text-sm'>
                {activeWorkers} {t('CurrentWorkforceCards.workerCount')}
              </p>
            </div>
          </Tooltip>
        </li>
      ))}
    </ul>
  )
}

export default CurrentWorkforceCards

const Skeleton = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
        <li
          key={number}
          className='bg-white rounded-lg shadow h-full w-44 inline-block'
        >
          <div className='w-full h-full flex-center text-center flex-col p-4 space-y-2 animate-pulse'>
            <div className='bg-gray-200 w-full h-8 rounded' />
            <div className='bg-gray-200 w-full h-4 rounded' />
            <div className='bg-gray-200 w-full h-4 rounded' />
          </div>
        </li>
      ))}
    </>
  )
}
