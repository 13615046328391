import { useMutation } from '@apollo/client'
import Button from 'base-components/Button'
import { format } from 'date-fns'
import {
  CommentFilter,
  CommentParts,
  updateCommentMutation,
  updateCommentMutationVariables,
  WorkdaysWithCommentsVariables,
  WorkdayWithCommentsParts,
} from 'generatedTypes'
import { UpdateCommentMutation } from 'graphql/mutations/Comment'
import { WorkdaysWithCommentsQuery } from 'graphql/queries/Workday'
import useQueryParam from 'hooks/useQueryParam'
import { getDateLocale } from 'init/i18n'
import { useWorkspace } from 'providers/WorkspaceProvider'
import { FC, useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { IoChatboxEllipsesOutline } from 'react-icons/io5'
import { CommentFilterType } from './CommentFilter'
import PaginatedList from './PaginatedList'

interface CommentsProps {}

const Comments: FC<CommentsProps> = () => {
  const { t, i18n } = useTranslation()
  const { selectedWorkspace } = useWorkspace()
  const [filterUrl] = useQueryParam<CommentFilterType>('commentFilter')

  const [updateComment] = useMutation<
    updateCommentMutation,
    updateCommentMutationVariables
  >(UpdateCommentMutation)

  const handleResolveComment = useCallback(
    async (comment: CommentParts, checked: boolean) => {
      try {
        await toast.promise(
          updateComment({
            variables: {
              data: {
                id: comment.id,
                resolved: checked,
              },
            },
            optimisticResponse: {
              updateComment: {
                ...comment,
                resolved: checked,
              },
            },
          }),
          {
            loading: t('Comments.resolveComment_loading'),
            success: t('Comments.resolveComment_sucess'),
            error: t('Comments.resolveComment_error'),
          },
        )
      } catch (error) {
        console.error(error)
      }
    },
    [t, updateComment],
  )

  const rows = useCallback(
    (workday: WorkdayWithCommentsParts) => {
      const comments: CommentParts[] = workday.WorkdayMeta?.Comments ?? []
      return (
        workday.WorkdayMeta &&
        comments.length > 0 && (
          <div
            key={workday.id}
            className='flex px-3 pb-5 last-of-type:pb-0 my-2 space-x-3 rounded border-b  border-gray-200 last-of-type:border-none'
          >
            <div className='flex flex-col space-y-2'>
              <div className='flex flex-wrap pl-6 text-sm'>
                <span>
                  {workday.name} ({workday.personId}), {workday.orderName},{' '}
                  {format(new Date(workday.startTime), 'd MMMM HH:mm', {
                    locale: getDateLocale(i18n.language),
                  })}
                </span>
              </div>

              {comments.map((comment) => (
                <div key={comment.id} className='flex space-x-2'>
                  <input
                    className='rounded mt-2'
                    type='checkbox'
                    checked={comment.resolved}
                    onChange={(e) =>
                      handleResolveComment(comment, e.target.checked)
                    }
                  />

                  <div
                    className={`flex p-1 space-x-2 border-2 rounded bg-gray-50 ${
                      comment.resolved ? 'text-gray-400' : ''
                    }`}
                  >
                    <IoChatboxEllipsesOutline className='self-center' />

                    <span
                      className={`text-sm ${
                        comment.resolved ? 'line-through' : ''
                      }`}
                    >
                      "{comment.text}"
                    </span>
                  </div>

                  <div>
                    <label className='text-xs text-gray-400'>
                      {comment.Creator?.name ?? `${t('Comments.NotFound')}`}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )
    },
    [handleResolveComment, i18n.language, t],
  )

  const commentFilterParam = useMemo(() => {
    if (!filterUrl?.comment || filterUrl.comment.length === 0)
      return [CommentFilter.RESOLVED, CommentFilter.NOT_RESOLVED]

    const attestedFilter = []
    if (filterUrl.comment.includes('RESOLVED'))
      attestedFilter.push(CommentFilter.RESOLVED)
    if (filterUrl.comment.includes('NOT_RESOLVED'))
      attestedFilter.push(CommentFilter.NOT_RESOLVED)
    return attestedFilter
  }, [filterUrl])

  return (
    <>
      <PaginatedList<WorkdayWithCommentsParts, WorkdaysWithCommentsVariables>
        query={WorkdaysWithCommentsQuery}
        variables={{
          data: {
            workspaceId: selectedWorkspace?.id ?? '',
          },
          commentFilter: commentFilterParam,
        }}
        limit={5}
        renderLoadingListItem={() => (
          <div className='w-full h-[50px] bg-slate-200 rounded animate-pulse my-2' />
        )}
        skipQuery={!Boolean(selectedWorkspace?.id)}
        renderListItem={(workday) => rows(workday)}
        fetchMoreButton={
          <Button className='my-1'>{t('Comments.fetchMore')}</Button>
        }
      />
    </>
  )
}

export default Comments
