/* This example requires Tailwind CSS v2.0+ */
import { Listbox, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiSelector } from 'react-icons/hi'
import { IoCheckmark } from 'react-icons/io5'
export type selectMenuItem = {
  id: string
  name: string
  [key: string]: any
}
interface SelectMenuProps<T> {
  items: T[]
  label?: string
  selected?: T
  setSelected: (item: T | undefined) => void
  appendable?: boolean
  onAppend?: (value: string) => void
  deleteable?: boolean
  onDelete?: (id: string) => void
  borderless?: boolean
  dataTestId?: string
}

function SelectMenu<T>({
  items,
  label,
  selected,
  setSelected,
  borderless,
  dataTestId,
}: SelectMenuProps<T & { id: string; name: string }>) {
  const { t } = useTranslation()
  const [initialOpen, setInitialOpen] = useState(true)

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className='block text-sm font-medium text-gray-700'>
            {label}
          </Listbox.Label>
          <div className={`relative ${borderless ? 'border-none' : 'mt-1'}`}>
            <Listbox.Button
              data-testid={dataTestId}
              className={`bg-white relative w-full ${
                borderless ? '' : 'border'
              } border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
              <span className='block truncate'>
                {selected ? selected.name : t('SelectMenu.select')}
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <HiSelector
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                {items.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? ' bg-gray-100' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                    onMouseEnter={() => {
                      setInitialOpen(false)
                    }}
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {item.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? '' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            {(initialOpen || !active) && (
                              <IoCheckmark
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            )}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default SelectMenu
