import { gql } from '@apollo/client'

export const CreateUserMutation = gql`
  mutation CreateUser($data: UserSignupInput!) {
    createUser(data: $data) {
      id
      email
    }
  }
`

export const DeleteUserMutation = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const UpdateUserWorkspaceMutation = gql`
  mutation UpdateUserWorkspace($data: UserWorkspaceUpdateInput!) {
    updateUserWorkspace(data: $data) {
      id
      email
    }
  }
`
export const UpdateUserPrivilegeMutation = gql`
  mutation UpdateUserPrivilege($data: UserPrivilegeUpdateInput!) {
    updateUserPrivilege(data: $data) {
      isAdmin
      isElevatedUser
    }
  }
`
