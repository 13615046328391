import { getAuth } from '@firebase/auth'
import { FirebaseError } from 'firebase/app'
import { sendPasswordResetEmail } from 'firebase/auth'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type ResetType = { email: string }

const ResetPasswordPage: FC = () => {
  const [passwordHasReset, setPasswordHasReset] = useState(false)
  const { register, handleSubmit, getValues, reset } = useForm<ResetType>()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const resetPassword = async (data: ResetType) => {
    const auth = getAuth()

    try {
      await sendPasswordResetEmail(auth, data.email)
      setPasswordHasReset(true)
      reset()
    } catch (e) {
      const error = e as FirebaseError
      const errorCode = error.code

      if (errorCode === 'auth/user-not-found') {
        toast.error(t('ResetPasswordPage.no_user_found_with_this_email'))
      }
      reset()
    }
  }

  useEffect(() => {
    if (passwordHasReset) {
      setTimeout(() => navigate('/signin'), 10000)
    }
  }, [passwordHasReset, navigate, getValues])

  return (
    <>
      <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-md w-full space-y-8'>
          <div>
            <img
              className='mx-auto h-12 w-auto'
              src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
              alt='Workflow'
            />
            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
              {t('ResetPasswordPage.flexime_certification_portal')}
            </h2>

            {!passwordHasReset ? (
              <>
                <h2 className='mt-6 text-center text-sm text-gray-800'>
                  {t('ResetPasswordPage.reset_your_password')}
                </h2>
                <h2 className='mt-1 text-center text-sm text-gray-500'>
                  {t(
                    'ResetPasswordPage.enter_your_email_address_reset_password',
                  )}
                </h2>
              </>
            ) : (
              <div className='text-center text-sm mt-6'>
                <h2 className='text-gray-500'>
                  {t('ResetPasswordPage.an_email_has_been_sent_to')}
                  <h2 className='text-gray-800'>{getValues().email}</h2>
                  {t(
                    'ResetPasswordPage.with_instructions_to_reset_your_password',
                  )}
                </h2>
                <h2 className='mt-16 text-gray-500'>
                  {t('ResetPasswordPage.redirecting_you_to_the_sign_in_page')}
                </h2>
              </div>
            )}
          </div>

          {!passwordHasReset && (
            <form
              className='mt-8 space-y-6'
              onSubmit={handleSubmit(resetPassword)}
            >
              <div className='rounded-md shadow-sm -space-y-px'>
                <div>
                  <label htmlFor='email-address' className='sr-only'>
                    {t('ResetPasswordPage.email_address')}
                  </label>
                  <input
                    {...register('email', { required: true })}
                    type='email'
                    autoComplete='email'
                    className='appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                    placeholder={t('ResetPasswordPage.email_address')}
                  />
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  {t('ResetPasswordPage.reset_your_password')}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default ResetPasswordPage
