import React, { FC, useMemo } from 'react'
import './Spinner.css'

interface Props {
  color?: 'white' | 'primary' | 'dark'
  size?: number
  className?: string
}

const Spinner: FC<Props> = ({
  color = 'primary',
  size = 30,
  className = '',
}) => {
  const colorClass = useMemo(() => {
    if (color === 'white') {
      return 'text-white'
    } else if (color === 'primary') {
      return 'text-primary-500'
    } else if (color === 'dark') {
      return 'text-black'
    }
  }, [color])

  return (
    <svg
      style={{ width: size, height: size }}
      className={`${className} ${colorClass} spinner`}
      viewBox='0 0 50 50'
    >
      <circle
        className='path stroke-current'
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='5'
      ></circle>
    </svg>
  )
}

export default Spinner
