import { gql } from '@apollo/client'
import UserInviteParts from '../fragments/UserInviteParts'

export const CreateUserInviteMutation = gql`
  mutation CreateUserInvite($data: UserInviteCreateInput!) {
    createUserInvite(data: $data) {
      id
      ...UserInviteParts
    }
  }
  ${UserInviteParts}
`
export const RefreshUserInviteMutation = gql`
  mutation RefreshUserInvite($data: UserInviteRefreshInput!) {
    refreshUserInvite(data: $data) {
      id
      ...UserInviteParts
    }
  }
  ${UserInviteParts}
`
export const DeleteUserInviteMutation = gql`
  mutation DeleteUserInvite($data: UserInviteDeleteInput!) {
    deleteUserInvite(data: $data) {
      id
      ...UserInviteParts
    }
  }
  ${UserInviteParts}
`
