import { AnimatePresence, motion } from 'framer-motion'
import { CSSProperties, FC, ReactNode, ReactPortal } from 'react'
import { Arrow, ArrowProps, LayerProps } from 'react-laag'

interface Props {
  open: boolean
  layerProps: LayerProps
  arrowProps?: ArrowProps
  className?: string
  style?: CSSProperties
  renderLayer: (children: ReactNode) => ReactPortal | null
}

const PopoverCard: FC<Props> = ({
  open,
  layerProps,
  className = '',
  style = {},
  renderLayer,
  children,
  arrowProps,
}) => {
  return renderLayer(
    <AnimatePresence>
      {open && (
        <motion.div
          className={`${className} bg-white z-[1000] shadow-lg border border-gray-200 rounded-md space-y-4 clickaway-ignore`}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
          {...layerProps}
        >
          {children}
          {arrowProps && (
            <Arrow
              {...arrowProps}
              size={10}
              roundness={0}
              backgroundColor='#e5e7eb'
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>,
  )
}

export default PopoverCard
