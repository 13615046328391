import { useMutation } from '@apollo/client'
import Modal from 'base-components/Modal'
import ModalActionButtons from 'base-components/ModalActionButtons'
import ModalHeader from 'base-components/ModalHeader'
import { DeleteUserInvite, DeleteUserInviteVariables } from 'generatedTypes'
import { DeleteUserInviteMutation } from 'graphql/mutations/UserInvite'
import React, { FC } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface Props {
  userInvite: { id: string; email: string } | null
  open: boolean
  onClose: () => void
}

const DeleteUserInviteModal: FC<Props> = ({ userInvite, open, onClose }) => {
  const { t } = useTranslation()
  const [deleteUserInvite, { loading }] = useMutation<
    DeleteUserInvite,
    DeleteUserInviteVariables
  >(DeleteUserInviteMutation)
  const handleDeleteUserInvite = async () => {
    const toastId = toast.loading(
      t('DeleteUserInviteModal.deleteInvite_loading'),
    )
    try {
      if (userInvite) {
        await deleteUserInvite({
          variables: { data: { id: userInvite.id } },
          refetchQueries: ['UserInvites'],
          awaitRefetchQueries: true,
        })
        toast.success(t('DeleteUserInviteModal.deleteInvite_success'), {
          id: toastId,
        })
      } else {
        toast.error(t('DeleteUserInviteModal.deleteInvite_error'), {
          id: toastId,
        })
      }
      onClose()
    } catch (e) {
      console.error(e)
      toast.error(t('DeleteUserInviteModal.deleteInvite_error'), {
        id: toastId,
      })
    }
  }

  if (!userInvite) {
    return <div />
  }

  return (
    <Modal open={open} onClose={onClose} className='w-full max-w-lg'>
      <ModalHeader title={t('DeleteUserInviteModal.title')} onClose={onClose} />
      <p className='mt-4'>
        {`${t('DeleteUserInviteModal.deleteInvite_text')} `}
        <b>{userInvite.email}</b>?
      </p>
      <ModalActionButtons
        loading={loading}
        confirmDisabled={loading}
        confirmText='Ta bort'
        onCancel={onClose}
        onConfirm={handleDeleteUserInvite}
      />
    </Modal>
  )
}

export default DeleteUserInviteModal
