import {
  addDays,
  differenceInDays,
  endOfWeek,
  min,
  startOfDay,
  startOfWeek,
} from 'date-fns'
import { endOfDay } from 'date-fns/esm'
import useQueryParam from 'hooks/useQueryParam'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getDayFormatter } from 'utils/dateUtils'

export interface DateFiltersType {
  from: string
  to: string
}

const DateFilter = () => {
  const { t } = useTranslation()
  const fromRef = useRef<HTMLInputElement>(null)
  const toRef = useRef<HTMLInputElement>(null)
  const [filterUrl, setFilterUrls] =
    useQueryParam<DateFiltersType>('dateFilter')

  useEffect(() => {
    if (!toRef.current || !fromRef.current) return

    if (filterUrl) {
      fromRef.current.value = getDayFormatter(new Date(filterUrl.from))
      toRef.current.value = getDayFormatter(new Date(filterUrl.to))
    } else {
      fromRef.current.value = getDayFormatter(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
      )
      toRef.current.value = getDayFormatter(
        min([endOfWeek(new Date(), { weekStartsOn: 1 }), new Date()]),
      )
    }
  }, [filterUrl])

  const parseFromDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFilterUrls({ from: '', to: '' }, { clear: true })
      return
    }

    const from = startOfDay(new Date(e.target.value))

    let numOffsetDays = 0
    if (filterUrl) {
      numOffsetDays = differenceInDays(new Date(filterUrl.to), from)

      if (numOffsetDays < 0) {
        numOffsetDays = 0
      } else if (numOffsetDays > 7) {
        numOffsetDays = 7
      }
    }

    const to = endOfDay(addDays(from, numOffsetDays))
    if (toRef.current) {
      toRef.current.value = to.toISOString().split('T')[0]
    }

    setFilterUrls(
      { from: from.toISOString(), to: to.toISOString() },
      { replace: true },
    )
  }

  const parseToDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || !filterUrl) {
      setFilterUrls({ from: '', to: '' }, { clear: true })
      return
    }

    const to = endOfDay(new Date(e.target.value))

    setFilterUrls(
      { from: filterUrl.from, to: to.toISOString() },
      { replace: true },
    )
  }

  const minToDate = filterUrl
    ? getDayFormatter(new Date(filterUrl.from))
    : undefined

  const getMaxToDate = () => {
    const today = new Date()
    const fromDate = filterUrl ? new Date(filterUrl.from) : today
    const inOneWeek = addDays(fromDate, 7)

    return getDayFormatter(min([inOneWeek, today]))
  }

  return (
    <div className='flex gap-x-2 flex-wrap gap-y-5'>
      <div className='relative'>
        <label className='absolute -top-4 left-0 pl-1 text-xs text-gray-500 font-thin'>
          {t('DateFilter.from')}
        </label>
        <input
          id='from'
          ref={fromRef}
          onChange={parseFromDay}
          type='date'
          max={getDayFormatter(new Date())}
          className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block pr-2 sm:text-sm border-gray-300 rounded-md'
        />
      </div>

      <div className='relative'>
        <label className='absolute -top-4 left-0 pl-1 text-xs text-gray-500 font-thin'>
          {t('DateFilter.to')}
        </label>
        <input
          id='to'
          ref={toRef}
          onChange={parseToDay}
          type='date'
          min={minToDate}
          max={getMaxToDate()}
          className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block pr-2 sm:text-sm border-gray-300 rounded-md'
        />
      </div>
    </div>
  )
}

export default DateFilter
