import { gql } from '@apollo/client'

export default gql`
  fragment UserInviteParts on UserInvite {
    id
    createdAt
    expiresAt
    email
  }
`
