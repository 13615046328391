import { ApolloError, useMutation } from '@apollo/client'
import { OrdersQuery } from 'graphql/queries/Order'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { FC, useCallback, useMemo, useState } from 'react'
import useClickAway from 'react-cool-onclickoutside'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { RiCheckboxCircleFill, RiCloseCircleLine } from 'react-icons/ri'
import {
  OrderParts,
  OrdersVariables,
  Orders_orders,
  SortOrder,
  UpdateOrder,
  UpdateOrderVariables,
} from '../generatedTypes'
import { UpdateOrderMutation } from '../graphql/mutations/Order'
import DeleteOrderModal from './DeleteOrderModal'
import Table, { TableColumn, TableRowAction } from './Table'

interface OrdersTabularProps {}

const OrdersTabular: FC<OrdersTabularProps> = () => {
  const { t } = useTranslation()
  const [edit, setEdit] = useState('')
  const [deleteOrderId, setDeleteOrderId] = useState<string | null>(null)

  const { register, handleSubmit, reset, watch, setValue } = useForm<{
    factor: number
  }>()

  const handleReset = useCallback(() => {
    reset()
    setEdit('')
  }, [reset])
  const clickAwayRef = useClickAway(() => {
    handleReset()
  })
  const { selectedWorkspace } = useWorkspace()

  const [updateOrder] = useMutation<UpdateOrder, UpdateOrderVariables>(
    UpdateOrderMutation,
    {
      refetchQueries: ['Orders'],
    },
  )

  const handleEditFactor = useCallback(
    async (order: Orders_orders, factor: number) => {
      const toastId = toast.loading(t('OrdersTabular.updateOrder_loading'))
      const parsedFactor = Number(factor)

      try {
        if (isNaN(parsedFactor)) throw new Error('Not a number')
        if (!selectedWorkspace) throw new Error('No workspace selected')

        handleReset()
        await updateOrder({
          variables: {
            orderId: order.id,
            factor: parsedFactor,
          },
          optimisticResponse: {
            updateOrder: {
              ...order,
              factor: parsedFactor,
            },
          },
        })
        toast.success(t('OrdersTabular.updateOrder_success'), { id: toastId })
      } catch (e) {
        let error = e as ApolloError
        console.error(error)
        toast.error(t('OrdersTabular.updateOrder_error'), { id: toastId })
      }
    },
    [t, selectedWorkspace, handleReset, updateOrder],
  )

  const formattedFactor = useCallback(
    (order: OrderParts) => {
      return (
        <div className='justify-start items-center'>
          {edit !== order.id ? (
            <button
              className='flex justify-between rounded hover:bg-gray-200 px-1'
              onClick={() => {
                setEdit(order.id)
                setValue('factor', order.factor)
              }}
            >
              <span>{order.factor}</span>
            </button>
          ) : (
            <form
              ref={clickAwayRef}
              onSubmit={handleSubmit(() =>
                handleEditFactor(order, watch('factor')),
              )}
              className='flex relative'
            >
              <input
                className='h-5 rounded w-14 pl-1'
                type='number'
                step={0.1}
                autoFocus
                {...register('factor', {
                  required: true,
                  min: 0.0,
                  valueAsNumber: true,
                })}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    handleReset()
                  }
                }}
              />

              <button type='submit' className='absolute left-16 top-0'>
                <RiCheckboxCircleFill className='text-xl self-center text-gray-500 hover:text-gray-900 cursor-pointer' />
              </button>

              <RiCloseCircleLine
                className='absolute left-20 top-0 ml-2 text-xl self-center text-gray-500 hover:text-gray-900 cursor-pointer'
                onClick={handleReset}
              />
            </form>
          )}
        </div>
      )
    },
    [
      clickAwayRef,
      edit,
      handleEditFactor,
      handleReset,
      handleSubmit,
      register,
      setValue,
      watch,
    ],
  )

  const tableColumns = useMemo<TableColumn<OrderParts>[]>(() => {
    return [
      {
        id: 'name',
        title: t('OrdersTabular.name_column_name'),
      },
      {
        id: 'externalOrderId',
        title: t('OrdersTabular.name_column_externalId'),
        sortable: true,
      },
      { id: 'customer', title: t('OrdersTabular.name_column_customer') },
      {
        id: 'factor',
        title: t('OrdersTabular.name_column_factor'),
        sortable: true,
        formatter: (_, order) => formattedFactor(order),
      },
    ]
  }, [formattedFactor, t])

  const rowActions = useMemo<TableRowAction<OrderParts>[]>(
    () => [
      {
        title: t('OrdersTabular.action_delete'),
        onClick: (order) => setDeleteOrderId(order.id),
      },
    ],
    [t],
  )

  return (
    <>
      <Table<OrderParts, OrdersVariables>
        columns={tableColumns}
        query={OrdersQuery}
        variables={{
          workspaceId: selectedWorkspace?.id,
        }}
        skipQuery={!selectedWorkspace?.id}
        rowActions={rowActions}
        initialOrderBy={{ createdAt: SortOrder.desc }}
      />

      <DeleteOrderModal
        open={Boolean(deleteOrderId)}
        orderId={deleteOrderId}
        onClose={() => setDeleteOrderId(null)}
      />
    </>
  )
}

export default OrdersTabular
