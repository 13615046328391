import { Popover, Transition } from '@headlessui/react'
import useQueryParam from 'hooks/useQueryParam'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronDown } from 'react-icons/io5'

export interface CommentFilterType {
  comment: string[]
}

type filterMap = {
  id: 'comment'
  name: string
  options: {
    value: string
    label: string
  }[]
}

const CommentFilter = () => {
  const [filterUrl, setFilterUrls] =
    useQueryParam<CommentFilterType>('commentFilter')
  const { t } = useTranslation()

  const filters: filterMap[] = [
    {
      id: 'comment',
      name: t('CommentFilter.label'),
      options: [
        { value: 'RESOLVED', label: t('CommentFilter.handledLabel') },
        { value: 'NOT_RESOLVED', label: t('CommentFilter.unhandledLabel') },
      ],
    },
  ]

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    const name = e.target.name as keyof CommentFilterType

    let newFilterObject: CommentFilterType = {
      comment: [],
    }

    if (filterUrl) {
      newFilterObject = JSON.parse(JSON.stringify(filterUrl))
    }

    if (checked) {
      newFilterObject[name].push(value)
    } else {
      newFilterObject[name] = newFilterObject[name].filter(
        (item) => item !== value,
      )
    }

    if (newFilterObject.comment.length === 0) {
      setFilterUrls(newFilterObject, { clear: true })
    } else {
      setFilterUrls(newFilterObject, { replace: true })
    }
  }

  return (
    <div className='flex justify-end items-center space-x-5'>
      <Popover.Group className='hidden sm:flex sm:items-baseline sm:space-x-8 '>
        {filters.map((filter) => (
          <Popover
            as='div'
            key={filter.id}
            id='desktop-filter'
            className='relative inline-block text-left'
          >
            <div>
              <Popover.Button className='group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900'>
                <span>{filter.name}</span>
                {filterUrl && filterUrl[filter.id].length ? (
                  <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums'>
                    {filterUrl?.[filter.id].length}
                  </span>
                ) : null}
                <IoChevronDown
                  className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Popover.Panel className='origin-top-right z-10 absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='space-y-4'>
                  {filter.options.map((option) => (
                    <div key={option.value} className='flex items-center'>
                      <input
                        id={option.value}
                        value={option.value}
                        name={filter.id}
                        onChange={(e) => handleChange(e)}
                        defaultChecked={filterUrl?.[filter.id].includes(
                          option.value,
                        )}
                        type='checkbox'
                        className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                      />
                      <label
                        htmlFor={`${option.value}`}
                        className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        ))}
      </Popover.Group>
    </div>
  )
}

export default CommentFilter
