import { gql } from '@apollo/client'
import WorkspaceParts from 'graphql/fragments/WorkspaceParts'

export const WorkspacesQuery = gql`
  query Workspaces {
    workspaces {
      id
      ...WorkspaceParts
    }
  }
  ${WorkspaceParts}
`
export const WorkspaceActiveWorkforceQuery = gql`
  query WorkspaceActiveWorkforce($data: WorkspaceActiveWorkforceInput!) {
    workspaceActiveWorkforce(data: $data) {
      id
      activeWorkers
      name
    }
  }
`
