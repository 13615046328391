import { gql } from '@apollo/client'

export const CreateWorkspaceMutation = gql`
  mutation CreateWorkspace($name: String!) {
    createWorkspace(name: $name) {
      id
      createdAt
      updatedAt

      name
    }
  }
`

export const DeleteWorkspaceMutation = gql`
  mutation DeleteWorkspace($workspaceId: ID!) {
    deleteWorkspace(workspaceId: $workspaceId) {
      id
      createdAt
      updatedAt

      name
    }
  }
`

export const MassAttestWorkspaceMutation = gql`
  mutation MassAttestWorkspace($data: MassAttestWorkspaceInput!) {
    massAttestWorkspace(data: $data) {
      id
      createdAt
      updatedAt

      name
    }
  }
`
