import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './i18n-languages/en.json'
import sv from './i18n-languages/sv.json'
import swedishLocale from 'date-fns/locale/sv'
import englishLocale from 'date-fns/locale/en-GB'
export const getDateLocale = (lng: string) => {
  if (lng === 'sv') {
    return swedishLocale
  } else if (lng === 'en') {
    return englishLocale
  }
  console.error('Unknown language: ' + lng)
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      sv,
      en,
    },
    lng: 'sv', // if you're using a language detector, do not define the lng option
    fallbackLng: 'sv',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
