function offsetFromCursor(items: any, cursor: string, readField: any) {
  for (let i = items.length - 1; i >= 0; --i) {
    const item = items[i]
    if (readField('id', item) === cursor) {
      return i + 1
    }
  }
  return -1
}

function cursorBasedPagination<T, A = keyof T>(keyArgs?: A[]) {
  return {
    keyArgs: keyArgs || ['type'],
    merge(existing: any, incoming: any, params: any) {
      const { args, readField } = params
      const { cursor } = args

      if (!cursor) {
        return incoming
      }

      const merged = existing ? existing.slice(0) : []
      let offset = offsetFromCursor(merged, cursor, readField)

      if (offset < 0) offset = merged.length
      for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i]
      }

      return merged
    },
  }
}

export default cursorBasedPagination
