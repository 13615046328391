import { ApolloError, useMutation, useQuery } from '@apollo/client'
import Button from 'base-components/Button'
import { ExternalOrdersSearchQuery } from 'graphql/queries/Order'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { mergeRefs, useLayer } from 'react-laag'
import { useThrottle } from 'react-use'
import OrdersTabular from '../components/OrdersTabular'
import {
  CreateOrder,
  CreateOrderVariables,
  ExternalOrdersSearch,
  ExternalOrdersSearchVariables,
  ExternalOrdersSearch_ExternalOrdersSearch,
} from '../generatedTypes'
import { CreateOrderMutation } from '../graphql/mutations/Order'

interface SettingsPageProps {}

const SettingsPage: FC<SettingsPageProps> = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, watch, control } = useForm<{
    searchPhrase: string
    factor: number
  }>()

  const { selectedWorkspace } = useWorkspace()
  const { searchPhrase } = useWatch({ control })
  const throttledSearchPhrase = useThrottle(searchPhrase, 500)
  const [showDropdown, setShowDropdown] = React.useState(false)

  const { data } = useQuery<
    ExternalOrdersSearch,
    ExternalOrdersSearchVariables
  >(ExternalOrdersSearchQuery, {
    variables: { searchPhrase: throttledSearchPhrase ?? '' },
    skip: (throttledSearchPhrase?.length ?? 0) < 3,
  })
  const onFocus = useCallback(() => {
    if (data?.ExternalOrdersSearch.length) setShowDropdown(true)
  }, [data?.ExternalOrdersSearch.length])
  useEffect(() => {
    setShowDropdown((data?.ExternalOrdersSearch?.length ?? 0) > 0)
  }, [data?.ExternalOrdersSearch?.length])
  const [selectedExternalOrder, setSelectedExternalOrder] =
    useState<ExternalOrdersSearch_ExternalOrdersSearch | null>()
  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: showDropdown,
    onOutsideClick: () => setShowDropdown(false),

    overflowContainer: false, // we want the menu to stay within its scroll-container
    auto: true, // auto find a placement when required
    snap: true, // snap to the possible placements (not in between)
    placement: 'bottom-start', // we prefer placement on the bottom-side
    possiblePlacements: ['top-start', 'bottom-start'], // stick with bottom and top
    triggerOffset: 0, // place the menu directly to the trigger
    containerOffset: 16, // make sure the menu gets a bit of space with respect to the containers edges
  })
  const [createOrder] = useMutation<CreateOrder, CreateOrderVariables>(
    CreateOrderMutation,
    {
      refetchQueries: ['Orders'],
      awaitRefetchQueries: true,
    },
  )

  const handleAddRow = async () => {
    const toastId = toast.loading(t('SettingsPage.createOrder_loading'))
    try {
      if (!selectedWorkspace) throw new Error('No workspace selected')

      const factor = Number(watch('factor'))

      if (selectedExternalOrder)
        await createOrder({
          variables: {
            factor,
            workspaceId: selectedWorkspace.id,
            externalOrderId: selectedExternalOrder?.id,
          },
        })
      setSelectedExternalOrder(null)
      toast.success(t('SettingsPage.createOrder_success'), { id: toastId })
      reset()
    } catch (e) {
      let error = e as ApolloError
      console.error(error)
      toast.error(t('SettingsPage.createOrder_error'), { id: toastId })
    }
  }

  return (
    <>
      <div className='grid grid-cols-1 gap-4 lg:col-span-3'>
        <section aria-labelledby='section-1-title'>
          <div className='rounded-lg bg-white overflow-hidden shadow'>
            <div className=' space-y-4' data-testid='settings-route'>
              <div className='flex p-6'>
                <div className='flex flex-col space-y-1'>
                  <span className='block text-base text-gray-700 font-semibold mb-2'>
                    {t('SettingsPage.add_row_title')}
                  </span>

                  <form
                    className='flex flex-col space-y-2'
                    onSubmit={handleSubmit(handleAddRow)}
                  >
                    {selectedExternalOrder && (
                      <div className='flex-row gap-2 border border-gray-500 rounded pl-1 pr-2 flex items-center'>
                        {selectedExternalOrder.name}
                        <button
                          onClick={() => setSelectedExternalOrder(null)}
                          className='text-red-400 font-semibold p-2 '
                        >
                          x
                        </button>
                      </div>
                    )}
                    {!selectedExternalOrder && (
                      <div>
                        <input
                          {...triggerProps}
                          onFocus={onFocus}
                          className='border rounded pl-1 w-40 text-gray-900'
                          type='text'
                          autoComplete='off'
                          {...register('searchPhrase', {
                            required: true,
                          })}
                          ref={mergeRefs(
                            triggerProps.ref,
                            register('searchPhrase', {
                              required: true,
                            }).ref,
                          )}
                          placeholder={t('SettingsPage.externalOrderId')}
                        />
                        {showDropdown &&
                          renderLayer(
                            <div
                              className='bg-white shadow-lg flex flex-col'
                              {...layerProps}
                              style={{
                                ...layerProps.style,
                              }}
                            >
                              {data?.ExternalOrdersSearch.map(
                                (externalOrder) => (
                                  <button
                                    key={externalOrder.id}
                                    onClick={() =>
                                      setSelectedExternalOrder(externalOrder)
                                    }
                                    className='py-2 px-2 hover:bg-gray-200 flex justify-start items-center'
                                  >
                                    {`${externalOrder.id} - ${externalOrder.name}`}
                                    <span className='text-sm text-gray-500 ml-1'>
                                      ({externalOrder.customer})
                                    </span>
                                  </button>
                                ),
                              )}
                            </div>,
                          )}
                      </div>
                    )}
                    <input
                      className='border rounded pl-1 w-40 text-gray-900'
                      type='number'
                      step={0.1}
                      placeholder={t('SettingsPage.factor')}
                      {...register('factor', {
                        required: true,
                        min: 0.0,
                        valueAsNumber: true,
                      })}
                    />
                    <Button type='submit' className='justify-center self-start'>
                      {t('SettingsPage.add_row')}
                    </Button>
                  </form>
                </div>
              </div>

              <OrdersTabular />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SettingsPage
