import React, {
  DetailedHTMLProps,
  FC,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react'
import { BaseVariants } from '../models'

export interface ButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  variant?: BaseVariants
  iconLeft?: ReactNode
  iconRight?: ReactNode
  disabled?: boolean
  textRight?: ReactElement | string
  to?: any
  rel?: string
}

const Button: FC<ButtonProps> = ({
  children,
  size = 'md',
  variant = 'primary',
  iconLeft,
  iconRight,
  textRight,
  className = '',
  ...rest
}) => {
  const baseClass =
    'items-center border border-transparent rounded-md focus:outline-none transition ease-in-out duration-150 flex'

  const { buttonSizeClass, rightIconClass } = useMemo(() => {
    if (size === 'xs') {
      return {
        buttonSizeClass: 'px-2 py-1.5 text-xs',
        leftIconClass: 'mr-2.5 w-4 h-4',
        rightIconClass: 'ml-2.5 w-4 h-4',
      }
    }

    if (size === 'sm') {
      return {
        buttonSizeClass: 'px-3 py-2 text-sm',
        leftIconClass: 'mr-2.5 w-4 h-4',
        rightIconClass: 'ml-2.5 w-4 h-4',
      }
    }

    if (size === 'md') {
      return {
        buttonSizeClass: 'px-4 py-2.5 text-sm',
        leftIconClass: 'mr-2.5 w-5 h-5',
        rightIconClass: 'ml-2.5 w-5 h-5',
      }
    }

    if (size === 'lg') {
      return {
        buttonSizeClass: 'p-5 text-base',
        leftIconClass: 'mr-4 w-6 h-6',
        rightIconClass: 'ml-4 w-6 h-6',
      }
    }

    return {}
  }, [size])

  const variantClass = useMemo(() => {
    if (variant === 'white') {
      return 'focus:border-gray-300 focus:shadow-outline-gray-100 border border-gray-200'
    }

    if (variant === 'transparent') {
      return 'focus:border-gray-100 focus:shadow-outline-gray-10 border border-gray-200'
    }

    if (variant === 'light') {
      return 'bg-gray-100 text-gray-900 focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'dark') {
      return 'bg-gray-900 text-white focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'primary') {
      return 'bg-indigo-500 text-gray-200 focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'secondary') {
      return 'bg-indigo-200 text-gray-800 border-indigo-400 focus:border-indigo-500 focus:shadow-outline-gray-100'
    }

    if (variant === 'tertiary') {
      return 'bg-gray-100 text-gray-900 focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'destructive') {
      return 'bg-red-200 text-red-900 border-red-300 focus:border-gray-100 focus:shadow-outline-gray-100'
    }
  }, [variant])

  const disabledClass = useMemo(
    () => (rest.disabled ? 'opacity-50 cursor-default' : 'hover:opacity-90'),
    [rest.disabled],
  )

  return (
    <button
      {...rest}
      className={`${baseClass} ${buttonSizeClass} ${variantClass} ${disabledClass} ${className}`}
    >
      {iconLeft && <span className='mr-2'>{iconLeft}</span>}
      {children}
      {textRight ? (
        <span className='block text-xs ml-auto'>{textRight}</span>
      ) : null}
      {iconRight && <span className={rightIconClass}>{iconRight}</span>}
    </button>
  )
}

export default Button
