import { useMutation, useQuery } from '@apollo/client'
import Button from 'base-components/Button'
import Spinner from 'base-components/Spinner'
import { addMonths } from 'date-fns'
import {
  CreateReport,
  CreateReportVariables,
  ReportType,
  Stats,
  StatsVariables,
} from 'generatedTypes'
import { CreateReportMutation } from 'graphql/mutations/Report'
import { StatsQuery } from 'graphql/queries/Stats'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

function downloadURI(uri: string, name: string) {
  var link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
const Label = (props: any) => {
  return <label className='block mb-4' {...props} />
}
const ReportPage: React.FC = () => {
  const { t } = useTranslation()
  const [createReport, { loading }] = useMutation<
    CreateReport,
    CreateReportVariables
  >(CreateReportMutation)

  const [selectedDateRange, setSelectedDateRange] = React.useState<{
    from: Date
    to: Date
  }>()
  const workspace = useWorkspace()
  const skip = !workspace.selectedWorkspace
  const { data, loading: statsLoading } = useQuery<Stats, StatsVariables>(
    StatsQuery,
    {
      variables: { workspaceId: workspace.selectedWorkspace?.id ?? '' },
      skip,
    },
  )

  const fteChart = data?.stats.map((stat) => ({
    name: stat.order,
    fte: Number(stat.fte.toFixed(2)),
    hc: Number(stat.hc.toFixed(2)),
  }))

  const hasChartData = Boolean(fteChart?.length)
  const handleExport = async (type: ReportType) => {
    if (workspace.selectedWorkspace && selectedDateRange) {
      const res = await createReport({
        variables: {
          type,
          dateFrom: selectedDateRange.from,
          dateTo: selectedDateRange.to,
          workspaceId: workspace.selectedWorkspace?.id,
        },
      })
      downloadURI(res.data?.createReport ?? '', 'report.pdf')
    }
  }
  return (
    <div
      className='grid grid-cols-1 gap-4 lg:col-span-3 order-2 lg:order-1'
      data-testid='report-route'
    >
      <section aria-labelledby='section-1-title'>
        <div className='rounded-lg bg-white overflow-hidden shadow p-4 flex flex-col'>
          <h1 className='mb-4'>{t('ReportPage.title')}</h1>
          <h3 className='mb-3'>{t('ReportPage.statsTitle')}</h3>
          <div className='max-w-xl flex flex-col lg:flex-row h-96 lg:h-48 w-full'>
            {!statsLoading && !hasChartData && !skip && (
              <div>{t('ReportPage.missingData')}</div>
            )}
            {statsLoading ? (
              <Spinner />
            ) : (
              hasChartData && (
                <div className='flex-1 flex flex-col'>
                  <h2 className='text-center'>FTE/HC</h2>
                  <ResponsiveContainer width='100%' height='100%'>
                    <BarChart data={fteChart}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey='fte' fill='#8884d8' />
                      <Bar dataKey='hc' fill='#82ca9d' />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )
            )}
          </div>
          <div>
            <div>
              <h3 className='mb-3'>{t('ReportPage.export')}</h3>
              <Label>{t('ReportPage.dateRange')}</Label>

              <input
                data-testid='export-date-input'
                onChange={(e) =>
                  setSelectedDateRange({
                    from: new Date(e.currentTarget.value),
                    to: addMonths(new Date(e.currentTarget.value), 1),
                  })
                }
                type='month'
                className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  pr-12 sm:text-sm border-gray-300 rounded-md mb-4'
              />
              {!loading ? (
                <div className='flex flex-row gap-2'>
                  {/* <Button
                    disabled={
                      !selectedDateRange?.from || !selectedDateRange?.to
                    }
                    onClick={() => handleExport(ReportType.HC)}
                    data-testid='export-button'
                  >
                    {t('ReportPage.exportHC')}
                  </Button> */}
                  <Button
                    disabled={
                      !selectedDateRange?.from || !selectedDateRange?.to
                    }
                    onClick={() => handleExport(ReportType.TIMEBASEDHC)}
                    data-testid='export-button'
                  >
                    {t('ReportPage.exportTimebasedHC')}
                  </Button>
                  <Button
                    disabled={
                      !selectedDateRange?.from || !selectedDateRange?.to
                    }
                    onClick={() => handleExport(ReportType.OVERTIME)}
                    data-testid='export-button'
                  >
                    {t('ReportPage.exportOvertime')}
                  </Button>
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ReportPage
