import { gql } from '@apollo/client'

export default gql`
  fragment UserParts on User {
    id
    createdAt
    email
    name
    isAdmin
    isSuperAdmin
    isElevatedUser
  }
`
