import { gql } from '@apollo/client'

export default gql`
  fragment WorkdayMetaParts on WorkdayMeta {
    id
    createdAt
    updatedAt
    externalWorkdayId
    attested

    attestedById
    CommentCount
    Comments {
      text
      id
      createdAt
      Creator {
        id
        name
      }
    }
    AttestedBy {
      id
      name
    }
  }
`
