import { gql } from '@apollo/client'

export default gql`
  fragment UsersParts on User {
    id
    createdAt
    email
    name
    isAdmin
    isSuperAdmin
    isElevatedUser
    Workspaces {
      id
    }
  }
`
