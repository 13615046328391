import { gql } from '@apollo/client'

export const StatsQuery = gql`
  query Stats($workspaceId: ID!) {
    stats(workspaceId: $workspaceId) {
      fte
      hc
      order
    }
  }
`
