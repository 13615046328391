import { gql } from '@apollo/client'
import CommentParts from './CommentParts'

export default gql`
  fragment WorkdayMetaWithCommentsParts on WorkdayMeta {
    id
    createdAt
    updatedAt
    externalWorkdayId
    attested

    attestedById
    CommentCount
    AttestedBy {
      id
      name
    }
    Comments {
      id
      ...CommentParts
    }
  }
  ${CommentParts}
`
