import { useMutation } from '@apollo/client'
import Modal from 'base-components/Modal'
import ModalActionButtons from 'base-components/ModalActionButtons'
import ModalHeader from 'base-components/ModalHeader'
import { CreateComment, CreateCommentVariables } from 'generatedTypes'
import { CreateCommentMutation } from 'graphql/mutations/Comment'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface Props {
  externalWorkdayId: number | undefined
  workspaceId: string | undefined
  open: boolean
  onClose: () => void
}

const CreateCommentModal: FC<Props> = ({
  externalWorkdayId,
  workspaceId,
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, watch } = useForm<{ text: string }>()
  const [createComment, { loading }] = useMutation<
    CreateComment,
    CreateCommentVariables
  >(CreateCommentMutation, { refetchQueries: ['WorkdaysWithComments'] })
  const handleCreateComment = async () => {
    const toastId = toast.loading(t('CreateCommentModal.creatingComment'))
    try {
      if (!workspaceId || !externalWorkdayId) {
        throw new Error('No workspaceId or externalWorkdayId')
      }

      await createComment({
        variables: {
          data: { externalWorkdayId, workspaceId, text: watch('text') },
        },
      })
      toast.success(t('CreateCommentModal.createCommentSuccess'), {
        id: toastId,
      })
      reset()

      onClose()
    } catch (e) {
      console.error(e)
      toast.error(t('CreateCommentModal.createCommentError'), {
        id: toastId,
      })
    }
  }

  return (
    <Modal open={open} onClose={onClose} className='w-full max-w-lg'>
      <ModalHeader title={t('CreateCommentModal.title')} onClose={onClose} />
      <form onSubmit={handleSubmit(handleCreateComment)}>
        <input
          type='text'
          className='mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md '
          placeholder={t('CreateCommentModal.createCommentPlaceholder')}
          {...register('text')}
        />
        <ModalActionButtons
          loading={loading}
          confirmDisabled={loading || !watch('text')}
          cancelText={t('CreateCommentModal.cancel')}
          confirmText={t('CreateCommentModal.confirm')}
          onCancel={onClose}
          onConfirm={handleCreateComment}
        />
      </form>
    </Modal>
  )
}

export default CreateCommentModal
