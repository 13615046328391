import { initializeApp } from 'firebase/app'

export const firebaseConfig = {
  apiKey: 'AIzaSyB-A64J_JIQWSXAUaRD4DiIisXG-p7Gtk0',
  authDomain: 'attest-portal.firebaseapp.com',
  projectId: 'attest-portal',
  storageBucket: 'attest-portal.appspot.com',
  messagingSenderId: '152204686819',
  appId: '1:152204686819:web:eece2f623b5469073d1cd6',
  measurementId: 'G-4LG0BP9354',
}

export const firebaseInit = () => {
  initializeApp(firebaseConfig)
}
