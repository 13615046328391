import { MutationFunctionOptions } from '@apollo/client'
import { FirebaseError } from 'firebase/app'
import { getAuth, OAuthProvider, signInWithPopup } from 'firebase/auth'
import { FC } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { IoLogoMicrosoft } from 'react-icons/io5'
import {
  CreateUser,
  CreateUserVariables,
  UserInvite_userInvite,
} from '../generatedTypes'

interface SignUpWithMicrosoftButtonProps {
  userInvite: UserInvite_userInvite
  setSigningUp: (signingUp: boolean) => void
  createUser: (
    options: MutationFunctionOptions<CreateUser, CreateUserVariables>,
  ) => Promise<any>
}

const SignUpWithMicrosoftButton: FC<SignUpWithMicrosoftButtonProps> = ({
  userInvite,
  setSigningUp,
  createUser,
}) => {
  const { t } = useTranslation()

  const signUpWithMicrosoft = async () => {
    try {
      setSigningUp(true)
      if (!userInvite) throw new Error('No user invite')

      const provider = new OAuthProvider('microsoft.com')
      const auth = getAuth()

      const result = await signInWithPopup(auth, provider)
      OAuthProvider.credentialFromResult(result)

      const data = {
        userInviteId: userInvite.id,
      }

      await createUser({ variables: { data } })
      window.location.reload()
    } catch (e) {
      const error = e as FirebaseError
      const code = error?.code ?? 'unknown'

      const messages: { [key: string]: string } = {
        'auth/account-exists-with-different-credential': t(
          'SignUpWithMicrosoftButton.auth/account-exists-with-different-credential',
        ),
        'auth/cancelled-popup-request': t(
          'SignUpWithMicrosoftButton.auth/cancelled-popup-request',
        ),
        'auth/popup-blocked': t('SignUpWithMicrosoftButton.auth/popup-blocked'),
        'auth/popup-closed-by-user': t(
          'SignUpWithMicrosoftButton.auth/popup-closed-by-user',
        ),
        'auth/unauthorized-domain': t(
          'SignUpWithMicrosoftButton.auth/unauthorized-domain',
        ),
      }

      toast.error(
        messages[code] ?? t('SignUpWithMicrosoftButton.unknown-error'),
        {
          duration: 5000,
        },
      )
    } finally {
      setSigningUp(false)
    }
  }

  return (
    <button onClick={signUpWithMicrosoft} className='cursor-pointer'>
      <span className='w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'>
        <span className='sr-only'>
          {t('SignUpWithMicrosoftButton.sign_in_with_microsoft')}
        </span>
        <IoLogoMicrosoft className='w-5 h-5' />
      </span>
    </button>
  )
}

export default SignUpWithMicrosoftButton
