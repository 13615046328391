import { useApolloClient, useQuery } from '@apollo/client'
import { WorkspacesQuery } from 'graphql/queries/Workspace'
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { WorkspaceParts, Workspaces } from '../generatedTypes'

export interface WorkspaceProviderValue {
  loading: boolean
  setSelectedWorkspace: (
    workspace: (WorkspaceParts & { id: string }) | undefined,
  ) => void
  workspaces: (WorkspaceParts & { id: string })[]
  selectedWorkspace: (WorkspaceParts & { id: string }) | undefined
}

const WorkspaceContext = createContext<WorkspaceProviderValue>({
  loading: true,
  setSelectedWorkspace: () => {},
  workspaces: [],
  selectedWorkspace: undefined,
})
const useWorkspace = () => useContext(WorkspaceContext)

const WorkspaceProvider: FC = ({ children }) => {
  const client = useApolloClient()
  const WorkspaceResponse = useQuery<Workspaces>(WorkspacesQuery)
  let [, setSearchParams] = useSearchParams()
  const [value, setValue, remove] = useLocalStorage('selectedWorkspaceId')
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    WorkspaceParts & { id: string }
  >()
  const workspaces = useMemo(
    () => WorkspaceResponse?.data?.workspaces,
    [WorkspaceResponse],
  )

  useEffect(() => {
    if (value) {
      const selectedWorkspace = workspaces?.find(
        (workspace) => workspace.id === value,
      )
      if (selectedWorkspace) {
        setSelectedWorkspace(selectedWorkspace)
      }
    } else {
      if (workspaces?.length) {
        setValue(workspaces[0].id)
      }
    }
  }, [setValue, value, workspaces])

  const handleSelectWorkspace = (
    workspace: (WorkspaceParts & { id: string }) | undefined,
  ) => {
    if (workspace) {
      setValue(workspace.id)
      setSelectedWorkspace(workspace)
      client.resetStore()
    } else {
      remove()
      setSelectedWorkspace(undefined)
    }

    // Reset all filters
    setSearchParams(new URLSearchParams())
  }
  return (
    <WorkspaceContext.Provider
      value={{
        loading: WorkspaceResponse.loading,
        workspaces: workspaces ?? [],
        selectedWorkspace,
        setSelectedWorkspace: handleSelectWorkspace,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}

export { WorkspaceProvider, useWorkspace }
