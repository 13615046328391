import { gql } from '@apollo/client'
import WorkdayMetaParts from './WorkdayMetaParts'

export default gql`
  fragment WorkdayParts on Workday {
    id
    name
    startTime
    endTime
    scheduledStartTime
    scheduledEndTime
    reportedStartTime
    reportedEndTime
    deviation

    overTime

    orderId
    orderName
    isBossConfirmed
    personId

    WorkdayMeta {
      id
      ...WorkdayMetaParts
    }
  }
  ${WorkdayMetaParts}
`
