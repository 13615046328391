import { gql } from '@apollo/client'
import UserInviteParts from '../fragments/UserInviteParts'

export const UserInviteQuery = gql`
  query UserInvite($id: ID!) {
    userInvite(id: $id) {
      id
      ...UserInviteParts
    }
  }
  ${UserInviteParts}
`

export const UserInvitesQuery = gql`
  query UserInvites(
    $limit: Int
    $cursor: String
    $orderBy: UserInvitesOrderInput
    $workspaceId: ID!
  ) {
    userInvites(
      limit: $limit
      cursor: $cursor
      orderBy: $orderBy
      workspaceId: $workspaceId
    ) {
      id
      ...UserInviteParts
    }
  }
  ${UserInviteParts}
`
