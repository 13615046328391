import { parse, stringify } from 'qs'
import React from 'react'
import { NavigateOptions, useSearchParams } from 'react-router-dom'

type optionsExtend = {
  clear?: boolean
}

export default function useQueryParam<T>(
  key: string,
): [
  T | undefined,
  (newQuery: T, options?: NavigateOptions & optionsExtend) => void,
] {
  let [searchParams, setSearchParams] = useSearchParams()
  let paramValue = searchParams.get(key)

  let value = React.useMemo(
    () => (paramValue ? (parse(paramValue) as unknown as T) : undefined),
    [paramValue],
  )

  let setValue = React.useCallback(
    (newValue: T, options?: NavigateOptions & optionsExtend) => {
      let newSearchParams = new URLSearchParams(searchParams)

      if (options?.clear) {
        newSearchParams.delete(key)
      } else {
        newSearchParams.set(key, stringify(newValue))
      }
      setSearchParams(newSearchParams, options)
    },
    [key, searchParams, setSearchParams],
  )

  return [value, setValue]
}
