import { ApolloError, useMutation } from '@apollo/client'
import Modal from 'base-components/Modal'
import ModalActionButtons from 'base-components/ModalActionButtons'
import ModalHeader from 'base-components/ModalHeader'
import {
  DeleteWorkspace,
  DeleteWorkspaceVariables,
  WorkspaceParts,
} from 'generatedTypes'
import { DeleteWorkspaceMutation } from 'graphql/mutations/Workspace'
import React, { FC } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface Props {
  workspace: WorkspaceParts | null
  open: boolean
  onClose: () => void
}

const DeleteUserInviteModal: FC<Props> = ({ workspace, open, onClose }) => {
  const { t } = useTranslation()

  const [deleteWorkspace, { loading }] = useMutation<
    DeleteWorkspace,
    DeleteWorkspaceVariables
  >(DeleteWorkspaceMutation)

  const handleDeleteWorkspace = async () => {
    if (!workspace) throw new Error('workspace is undefined')

    const toastId = toast.loading(t('DeleteWorkspaceModal.loading'))
    try {
      await deleteWorkspace({
        variables: {
          workspaceId: workspace.id,
        },
        refetchQueries: ['Workspaces'],
      })
      toast.success(t('DeleteWorkspaceModal.success'), {
        id: toastId,
      })
      onClose()
    } catch (e) {
      let error = e as ApolloError
      console.error(error)
      toast.error(t('DeleteWorkspaceModal.error'), {
        id: toastId,
      })
    }
  }

  if (!workspace) {
    return <div />
  }

  return (
    <Modal open={open} onClose={onClose} className='w-full max-w-lg'>
      <ModalHeader title={t('DeleteWorkspaceModal.title')} onClose={onClose} />
      <p className='mt-4'>
        {`${t('DeleteWorkspaceModal.text')} `}
        <b>{workspace.name}</b>?
      </p>
      <ModalActionButtons
        loading={loading}
        confirmDisabled={loading}
        confirmText={t('DeleteWorkspaceModal.confirm')}
        onCancel={onClose}
        onConfirm={handleDeleteWorkspace}
      />
    </Modal>
  )
}

export default DeleteUserInviteModal
