import { FirebaseError } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SignInWithMicrosoftButton from '../components/SignInWithMicrosoftButton'

type LoginType = { email: string; password: string }

const SignIn = () => {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm<LoginType>()

  const SignInWithEmail = async (data: LoginType) => {
    try {
      const auth = getAuth()
      await signInWithEmailAndPassword(auth, data.email, data.password)
    } catch (e) {
      const error = e as FirebaseError
      const code = error?.code ?? 'unknown'

      const messages: { [key: string]: string } = {
        'auth/account-exists-with-different-credential': t(
          'SignIn.auth/account-exists-with-different-credential',
        ),
        'auth/cancelled-popup-request': t(
          'SignIn.auth/cancelled-popup-request',
        ),
        'auth/popup-blocked': t('SignIn.auth/popup-blocked'),
        'auth/popup-closed-by-user': t('SignIn.auth/popup-closed-by-user'),
        'auth/unauthorized-domain': t('SignIn.auth/unauthorized-domain'),
      }

      toast.error(messages[code] ?? t('SignIn.unknown-error'), {
        duration: 5000,
      })
    }
  }

  return (
    <Window>
      <form onSubmit={handleSubmit(SignInWithEmail)} className='space-y-6'>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700'
          >
            Email address
          </label>
          <div className='mt-1'>
            <input
              data-test='email'
              id='email'
              {...register('email', { required: true })}
              type='email'
              autoComplete='email'
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
            />
          </div>
        </div>

        <div>
          <label
            htmlFor='password'
            className='block text-sm font-medium text-gray-700'
          >
            Password
          </label>
          <div className='mt-1'>
            <input
              {...register('password', { required: true })}
              type='password'
              id='password'
              data-test='password'
              autoComplete='current-password'
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
            />
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='text-sm'>
            <Link
              to='/resetPassword'
              className='font-medium text-indigo-600 hover:text-indigo-500'
            >
              Forgot your password?
            </Link>
          </div>
        </div>

        <input
          value='Sign in'
          type='submit'
          className='w-full flex justify-center cursor-pointer py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        />
      </form>

      <div className='mt-6'>
        <div className='relative'>
          <div className='absolute inset-0 flex items-center'>
            <div className='w-full border-t border-gray-300' />
          </div>
          <div className='relative flex justify-center text-sm'>
            <span className='px-2 bg-white text-gray-500'>
              Or continue with
            </span>
          </div>
        </div>

        <div className='mt-6 grid grid-cols-1 gap-3'>
          <SignInWithMicrosoftButton />
        </div>
      </div>
    </Window>
  )
}

const Window: FC = ({ children }) => {
  return (
    <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
        {children}
      </div>
    </div>
  )
}

export default SignIn
