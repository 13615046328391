import { AnimatePresence, motion } from 'framer-motion'
import React, { CSSProperties, FC, useEffect, useRef } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { createPortal } from 'react-dom'
import { useKey } from 'react-use'
export interface ModalProps {
  open: boolean
  className?: string
  style?: CSSProperties
  onClose: () => void
  dataTestId?: string
}

const Modal: FC<ModalProps> = ({
  open,
  className,
  onClose,
  style,
  children,
  dataTestId,
}) => {
  const rootElemRef = useRef(document.createElement('div'))
  useEffect(() => {
    const rootElem = rootElemRef.current
    let modalRoot = document.getElementById('modal-root')
    if (!modalRoot) {
      modalRoot = document.body
    }

    modalRoot?.appendChild(rootElem)
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
      rootElem.remove()
    }
  }, [open])

  const modalRef = useOnclickOutside(onClose, {
    ignoreClass: 'clickaway-ignore',
  })

  useKey('Escape', onClose)

  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          data-testid={dataTestId}
          initial={{ opacity: 0, scale: 0.93, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.93, y: 10 }}
          transition={{ duration: 0.2 }}
          className='fixed inset-0'
          style={{ zIndex: 1000 }}
        >
          <div
            className='fixed inset-0 bg-black'
            style={{ zIndex: 1001, opacity: 0.6, transform: 'scale(1.3)' }}
          />
          <div
            className='fixed inset-0 flex justify-center items-center p-12 overflow-y-auto'
            style={{ zIndex: 1002 }}
          >
            <div
              ref={modalRef}
              className={`${className} bg-white rounded-lg shadow-lg px-7 py-5`}
              style={style}
            >
              {children}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    rootElemRef.current,
  )
}

export default Modal
