import { useQuery } from '@apollo/client'
import SelectMenu from 'base-components/SelectMenu'
import { WorkspaceParts, Workspaces } from 'generatedTypes'
import { WorkspacesQuery } from 'graphql/queries/Workspace'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { useMemo } from 'react'
type Props = {
  borderless?: boolean
}
const WorkspaceSelector: React.FC<Props> = ({ borderless }) => {
  const WorkspaceResponse = useQuery<Workspaces>(WorkspacesQuery)

  const workspaces = useMemo(
    () => WorkspaceResponse?.data?.workspaces,
    [WorkspaceResponse],
  )

  const { selectedWorkspace, setSelectedWorkspace } = useWorkspace()

  return (
    <SelectMenu<WorkspaceParts & { id: string }>
      borderless={borderless}
      items={workspaces ?? []}
      selected={selectedWorkspace}
      setSelected={setSelectedWorkspace}
      dataTestId='workspace-selector'
    />
  )
}

export default WorkspaceSelector
