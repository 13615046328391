import { QueryResult } from '@apollo/client'
import {
  AttestFilter,
  Orders,
  OrdersVariables,
  WorkdayDeviation,
} from 'generatedTypes'
import useQueryParam from 'hooks/useQueryParam'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronDown } from 'react-icons/io5'
import { Arrow, useLayer, useMousePositionAsTrigger } from 'react-laag'
import DateFilter from './DateFilter'

export interface FiltersType {
  orderIds?: string[]
  attested?: string[]
  comment?: string[]
  deviation?: string[]
  punchedIn?: string[]
}

interface FiltersProps {
  orders: QueryResult<Orders, OrdersVariables>
}

type FilterMap = {
  id: 'attested' | 'orderIds' | 'comment' | 'deviation' | 'punchedIn'
  name: string
  defaultValue?: string[]
  options: {
    value: string
    label: string
  }[]
}

const Filters: FC<FiltersProps> = ({ orders }) => {
  const [filterUrl, setFilterUrls] = useQueryParam<FiltersType>('filter')
  const { t } = useTranslation()

  const ordersList = orders.data?.orders ?? []

  const filters: FilterMap[] = [
    {
      id: 'attested',
      name: t('Filters.attestedLabel'),
      defaultValue: [AttestFilter.ATTESTED],
      options: [
        { value: 'isAttested', label: t('Filters.attestedLabel') },
        { value: 'isNotAttested', label: t('Filters.notAttestedLabel') },
      ],
    },
    {
      id: 'orderIds',
      name: t('Filters.orderIdsLabel'),
      options: ordersList.map((order) => ({
        value: order.externalOrderId.toString(),
        label: `${order.externalOrderId} - ${order.name}`,
      })),
    },
    {
      id: 'comment',
      name: t('Filters.commentLabel'),
      options: [
        { value: 'withComments', label: t('Filters.withComments') },
        { value: 'withoutComments', label: t('Filters.withoutComments') },
      ],
    },
    {
      id: 'deviation',
      name: t('Filters.deviationLabel'),
      options: [
        {
          value: WorkdayDeviation.MISSING_TIMESTAMP,
          label: t('Filters.missingTimestamp'),
        },
        {
          value: WorkdayDeviation.CHANGED_SCHEDULE,
          label: t('Filters.deviatingSchedule'),
        },
      ],
    },
    {
      id: 'punchedIn',
      name: t('Filters.punchedInLabel'),
      options: [
        {
          value: 'true',
          label: t('Filters.punchedInLabel'),
        },
      ],
    },
  ]
  const changeURLFilterParams = useCallback(
    (
      filterUrl: FiltersType | undefined,
      args: { filter: keyof FiltersType; checked: boolean; value: string }[],
    ) => {
      const newFilterObject: FiltersType = {
        attested: filterUrl?.['attested'] ?? [],
        orderIds: filterUrl?.['orderIds'] ?? [],
        comment: filterUrl?.['comment'] ?? [],
        deviation: filterUrl?.['deviation'] ?? [],
        punchedIn: filterUrl?.['punchedIn'] ?? [],
      }
      for (const arg of args) {
        const { checked, filter, value } = arg
        if (checked && !filterUrl?.[filter]?.includes(value)) {
          newFilterObject[filter]?.push?.(value)
        } else if (!checked) {
          newFilterObject[filter] = newFilterObject[filter]?.filter(
            (item) => item !== value,
          )
        }
      }

      if (
        !newFilterObject.attested?.length &&
        !newFilterObject.orderIds?.length &&
        !newFilterObject.comment?.length &&
        !newFilterObject.deviation?.length &&
        !newFilterObject.punchedIn?.length
      ) {
        setFilterUrls(newFilterObject, { clear: true })
      } else {
        setFilterUrls(newFilterObject, { replace: true })
      }
    },
    [setFilterUrls],
  )
  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current && !filterUrl) {
      changeURLFilterParams(filterUrl, [
        { filter: 'attested', checked: true, value: 'isNotAttested' },
        { filter: 'comment', checked: true, value: 'withoutComments' },
        { filter: 'deviation', checked: true, value: 'MISSING_TIMESTAMP' },
        { filter: 'deviation', checked: true, value: 'CHANGED_SCHEDULE' },
      ])
    }
    firstRender.current = false
  }, [changeURLFilterParams, filterUrl])
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target
      const filter = e.target.name as keyof FiltersType

      changeURLFilterParams(filterUrl, [{ filter, checked, value }])
    },
    [changeURLFilterParams, filterUrl],
  )
  const { hasMousePosition, handleMouseEvent, resetMousePosition, trigger } =
    useMousePositionAsTrigger()

  const { layerProps, renderLayer, arrowProps } = useLayer({
    isOpen: hasMousePosition,
    onOutsideClick: resetMousePosition,
    auto: true,
    placement: 'bottom-center',
    arrowOffset: 16,
    triggerOffset: 24,
    containerOffset: 16,
    trigger,
  })
  const [selectedFilter, setSelectedFilter] = useState<FilterMap>()
  return (
    <div className='grid grid-cols-1 lg:flex lg:justify-start lg:items-center gap-x-5 gap-y-4 '>
      {filters.map((filter) => (
        <div key={filter.id}>
          <div>
            <button
              onClick={(e) => {
                setSelectedFilter(filter)
                handleMouseEvent(e)
              }}
              className='group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900'
            >
              <span>{filter.name}</span>
              {filterUrl && filterUrl[filter.id]?.length ? (
                <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums'>
                  {filterUrl?.[filter.id]?.length}
                </span>
              ) : null}
              <IoChevronDown
                className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                aria-hidden='true'
              />
            </button>
          </div>
        </div>
      ))}
      {hasMousePosition &&
        selectedFilter &&
        renderLayer(
          <div
            className='gap-y-4 bg-white rounded shadow p-4 max-w-xs'
            {...layerProps}
          >
            {selectedFilter.options.map((option) => (
              <div key={option.value} className='flex items-center'>
                <input
                  id={option.value}
                  value={option.value}
                  name={selectedFilter.id}
                  onChange={(e) => handleChange(e)}
                  defaultChecked={filterUrl?.[selectedFilter.id]?.includes?.(
                    option.value,
                  )}
                  type='checkbox'
                  className='h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                />
                <label
                  htmlFor={`${option.value}`}
                  className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap truncate'
                >
                  {option.label}
                </label>
              </div>
            ))}
            <Arrow {...arrowProps} />
          </div>,
        )}
      <DateFilter />
    </div>
  )
}

export default Filters
