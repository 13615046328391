import { gql } from '@apollo/client'
import UsersParts from 'graphql/fragments/UsersParts'
import UserParts from '../fragments/UserParts'

export const MeQuery = gql`
  query Me {
    me {
      id
      ...UserParts
    }
  }
  ${UserParts}
`

export const UsersQuery = gql`
  query Users {
    users {
      id
      ...UsersParts
    }
  }
  ${UsersParts}
`
