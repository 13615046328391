import { gql } from '@apollo/client'
import OrderParts from 'graphql/fragments/OrderParts'

export const OrdersQuery = gql`
  query Orders(
    $workspaceId: ID!
    $limit: Int
    $cursor: String
    $orderBy: OrdersOrderInput
  ) {
    orders(
      workspaceId: $workspaceId
      limit: $limit
      cursor: $cursor
      orderBy: $orderBy
    ) {
      id
      ...OrderParts
    }
  }
  ${OrderParts}
`

export const ExternalOrdersSearchQuery = gql`
  query ExternalOrdersSearch($searchPhrase: String!) {
    ExternalOrdersSearch(searchPhrase: $searchPhrase) {
      name
      id
      customer
    }
  }
`
