/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateComment
// ====================================================

export interface CreateComment_createComment_WorkdayMeta_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface CreateComment_createComment_WorkdayMeta_Comments {
  __typename: "Comment";
  text: string;
  id: string;
  createdAt: any;
  Creator: CreateComment_createComment_WorkdayMeta_Comments_Creator | null;
}

export interface CreateComment_createComment_WorkdayMeta_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface CreateComment_createComment_WorkdayMeta {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  Comments: CreateComment_createComment_WorkdayMeta_Comments[] | null;
  AttestedBy: CreateComment_createComment_WorkdayMeta_AttestedBy | null;
}

export interface CreateComment_createComment {
  __typename: "Workday";
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  scheduledStartTime: string | null;
  scheduledEndTime: string | null;
  reportedStartTime: string | null;
  reportedEndTime: string | null;
  deviation: WorkdayDeviation | null;
  overTime: number | null;
  orderId: number;
  orderName: string;
  isBossConfirmed: boolean | null;
  personId: number;
  WorkdayMeta: CreateComment_createComment_WorkdayMeta | null;
}

export interface CreateComment {
  createComment: CreateComment_createComment;
}

export interface CreateCommentVariables {
  data: CreateCommentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteComment
// ====================================================

export interface DeleteComment_deleteComment_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface DeleteComment_deleteComment {
  __typename: "Comment";
  id: string;
  createdAt: any;
  updatedAt: any;
  text: string;
  resolved: boolean;
  Creator: DeleteComment_deleteComment_Creator | null;
}

export interface DeleteComment {
  deleteComment: DeleteComment_deleteComment;
}

export interface DeleteCommentVariables {
  data: DeleteCommentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCommentMutation
// ====================================================

export interface updateCommentMutation_updateComment_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface updateCommentMutation_updateComment {
  __typename: "Comment";
  id: string;
  createdAt: any;
  updatedAt: any;
  text: string;
  resolved: boolean;
  Creator: updateCommentMutation_updateComment_Creator | null;
}

export interface updateCommentMutation {
  updateComment: updateCommentMutation_updateComment;
}

export interface updateCommentMutationVariables {
  data: UpdateCommentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrder
// ====================================================

export interface CreateOrder_createOrder_Workspace {
  __typename: "Workspace";
  id: string;
}

export interface CreateOrder_createOrder {
  __typename: "Order";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalOrderId: number;
  factor: number;
  name: string | null;
  customer: string;
  Workspace: CreateOrder_createOrder_Workspace | null;
}

export interface CreateOrder {
  createOrder: CreateOrder_createOrder;
}

export interface CreateOrderVariables {
  workspaceId: string;
  externalOrderId: number;
  factor: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrder
// ====================================================

export interface UpdateOrder_updateOrder_Workspace {
  __typename: "Workspace";
  id: string;
}

export interface UpdateOrder_updateOrder {
  __typename: "Order";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalOrderId: number;
  factor: number;
  name: string | null;
  customer: string;
  Workspace: UpdateOrder_updateOrder_Workspace | null;
}

export interface UpdateOrder {
  updateOrder: UpdateOrder_updateOrder;
}

export interface UpdateOrderVariables {
  orderId: string;
  factor: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrder
// ====================================================

export interface DeleteOrder_deleteOrder_Workspace {
  __typename: "Workspace";
  id: string;
}

export interface DeleteOrder_deleteOrder {
  __typename: "Order";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalOrderId: number;
  factor: number;
  name: string | null;
  customer: string;
  Workspace: DeleteOrder_deleteOrder_Workspace | null;
}

export interface DeleteOrder {
  deleteOrder: DeleteOrder_deleteOrder;
}

export interface DeleteOrderVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReport
// ====================================================

export interface CreateReport {
  createReport: string;
}

export interface CreateReportVariables {
  workspaceId: string;
  dateFrom: any;
  dateTo: any;
  type: ReportType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser {
  __typename: "User";
  id: string;
  email: string;
}

export interface CreateUser {
  createUser: CreateUser_createUser;
}

export interface CreateUserVariables {
  data: UserSignupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser_deleteUser {
  __typename: "User";
  id: string;
}

export interface DeleteUser {
  deleteUser: DeleteUser_deleteUser;
}

export interface DeleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserWorkspace
// ====================================================

export interface UpdateUserWorkspace_updateUserWorkspace {
  __typename: "User";
  id: string;
  email: string;
}

export interface UpdateUserWorkspace {
  updateUserWorkspace: UpdateUserWorkspace_updateUserWorkspace;
}

export interface UpdateUserWorkspaceVariables {
  data: UserWorkspaceUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserPrivilege
// ====================================================

export interface UpdateUserPrivilege_updateUserPrivilege {
  __typename: "User";
  isAdmin: boolean;
  isElevatedUser: boolean;
}

export interface UpdateUserPrivilege {
  updateUserPrivilege: UpdateUserPrivilege_updateUserPrivilege;
}

export interface UpdateUserPrivilegeVariables {
  data: UserPrivilegeUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserInvite
// ====================================================

export interface CreateUserInvite_createUserInvite {
  __typename: "UserInvite";
  id: string;
  createdAt: any;
  expiresAt: any;
  email: string;
}

export interface CreateUserInvite {
  createUserInvite: CreateUserInvite_createUserInvite;
}

export interface CreateUserInviteVariables {
  data: UserInviteCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefreshUserInvite
// ====================================================

export interface RefreshUserInvite_refreshUserInvite {
  __typename: "UserInvite";
  id: string;
  createdAt: any;
  expiresAt: any;
  email: string;
}

export interface RefreshUserInvite {
  refreshUserInvite: RefreshUserInvite_refreshUserInvite;
}

export interface RefreshUserInviteVariables {
  data: UserInviteRefreshInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserInvite
// ====================================================

export interface DeleteUserInvite_deleteUserInvite {
  __typename: "UserInvite";
  id: string;
  createdAt: any;
  expiresAt: any;
  email: string;
}

export interface DeleteUserInvite {
  deleteUserInvite: DeleteUserInvite_deleteUserInvite;
}

export interface DeleteUserInviteVariables {
  data: UserInviteDeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWorkdayMeta
// ====================================================

export interface UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta_Comments {
  __typename: "Comment";
  text: string;
  id: string;
  createdAt: any;
  Creator: UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta_Comments_Creator | null;
}

export interface UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  Comments: UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta_Comments[] | null;
  AttestedBy: UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta_AttestedBy | null;
}

export interface UpdateWorkdayMeta_updateWorkdayMeta {
  __typename: "Workday";
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  scheduledStartTime: string | null;
  scheduledEndTime: string | null;
  reportedStartTime: string | null;
  reportedEndTime: string | null;
  deviation: WorkdayDeviation | null;
  overTime: number | null;
  orderId: number;
  orderName: string;
  isBossConfirmed: boolean | null;
  personId: number;
  WorkdayMeta: UpdateWorkdayMeta_updateWorkdayMeta_WorkdayMeta | null;
}

export interface UpdateWorkdayMeta {
  updateWorkdayMeta: UpdateWorkdayMeta_updateWorkdayMeta;
}

export interface UpdateWorkdayMetaVariables {
  data: WorkdayMetaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspace
// ====================================================

export interface CreateWorkspace_createWorkspace {
  __typename: "Workspace";
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
}

export interface CreateWorkspace {
  createWorkspace: CreateWorkspace_createWorkspace;
}

export interface CreateWorkspaceVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWorkspace
// ====================================================

export interface DeleteWorkspace_deleteWorkspace {
  __typename: "Workspace";
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
}

export interface DeleteWorkspace {
  deleteWorkspace: DeleteWorkspace_deleteWorkspace;
}

export interface DeleteWorkspaceVariables {
  workspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MassAttestWorkspace
// ====================================================

export interface MassAttestWorkspace_massAttestWorkspace {
  __typename: "Workspace";
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
}

export interface MassAttestWorkspace {
  massAttestWorkspace: MassAttestWorkspace_massAttestWorkspace | null;
}

export interface MassAttestWorkspaceVariables {
  data: MassAttestWorkspaceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Orders
// ====================================================

export interface Orders_orders_Workspace {
  __typename: "Workspace";
  id: string;
}

export interface Orders_orders {
  __typename: "Order";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalOrderId: number;
  factor: number;
  name: string | null;
  customer: string;
  Workspace: Orders_orders_Workspace | null;
}

export interface Orders {
  orders: Orders_orders[];
}

export interface OrdersVariables {
  workspaceId: string;
  limit?: number | null;
  cursor?: string | null;
  orderBy?: OrdersOrderInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExternalOrdersSearch
// ====================================================

export interface ExternalOrdersSearch_ExternalOrdersSearch {
  __typename: "ExternalOrder";
  name: string;
  id: number;
  customer: string;
}

export interface ExternalOrdersSearch {
  ExternalOrdersSearch: ExternalOrdersSearch_ExternalOrdersSearch[];
}

export interface ExternalOrdersSearchVariables {
  searchPhrase: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Stats
// ====================================================

export interface Stats_stats {
  __typename: "Stats";
  fte: number;
  hc: number;
  order: string;
}

export interface Stats {
  stats: Stats_stats[];
}

export interface StatsVariables {
  workspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me {
  __typename: "User";
  id: string;
  createdAt: any;
  email: string;
  name: string | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isElevatedUser: boolean;
}

export interface Me {
  me: Me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Users
// ====================================================

export interface Users_users_Workspaces {
  __typename: "Workspace";
  id: string;
}

export interface Users_users {
  __typename: "User";
  id: string;
  createdAt: any;
  email: string;
  name: string | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isElevatedUser: boolean;
  Workspaces: Users_users_Workspaces[] | null;
}

export interface Users {
  users: Users_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserInvite
// ====================================================

export interface UserInvite_userInvite {
  __typename: "UserInvite";
  id: string;
  createdAt: any;
  expiresAt: any;
  email: string;
}

export interface UserInvite {
  userInvite: UserInvite_userInvite | null;
}

export interface UserInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserInvites
// ====================================================

export interface UserInvites_userInvites {
  __typename: "UserInvite";
  id: string;
  createdAt: any;
  expiresAt: any;
  email: string;
}

export interface UserInvites {
  userInvites: UserInvites_userInvites[];
}

export interface UserInvitesVariables {
  limit?: number | null;
  cursor?: string | null;
  orderBy?: UserInvitesOrderInput | null;
  workspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Workdays
// ====================================================

export interface Workdays_workdays_WorkdayMeta_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface Workdays_workdays_WorkdayMeta_Comments {
  __typename: "Comment";
  text: string;
  id: string;
  createdAt: any;
  Creator: Workdays_workdays_WorkdayMeta_Comments_Creator | null;
}

export interface Workdays_workdays_WorkdayMeta_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface Workdays_workdays_WorkdayMeta {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  Comments: Workdays_workdays_WorkdayMeta_Comments[] | null;
  AttestedBy: Workdays_workdays_WorkdayMeta_AttestedBy | null;
}

export interface Workdays_workdays {
  __typename: "Workday";
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  scheduledStartTime: string | null;
  scheduledEndTime: string | null;
  reportedStartTime: string | null;
  reportedEndTime: string | null;
  deviation: WorkdayDeviation | null;
  overTime: number | null;
  orderId: number;
  orderName: string;
  isBossConfirmed: boolean | null;
  personId: number;
  WorkdayMeta: Workdays_workdays_WorkdayMeta | null;
}

export interface Workdays {
  workdays: Workdays_workdays[];
}

export interface WorkdaysVariables {
  orderIds: number[];
  workspaceId: string;
  dateFrom: any;
  dateTo: any;
  limit?: number | null;
  cursor?: string | null;
  attestFilter: AttestFilter[];
  commentFilter: WorkdayCommentFilter[];
  deviationFilter: WorkdayDeviation[];
  punchedInFilter?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WorkdaysWithComments
// ====================================================

export interface WorkdaysWithComments_workdaysWithComments_WorkdayMeta_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdaysWithComments_workdaysWithComments_WorkdayMeta_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdaysWithComments_workdaysWithComments_WorkdayMeta_Comments {
  __typename: "Comment";
  id: string;
  createdAt: any;
  updatedAt: any;
  text: string;
  resolved: boolean;
  Creator: WorkdaysWithComments_workdaysWithComments_WorkdayMeta_Comments_Creator | null;
}

export interface WorkdaysWithComments_workdaysWithComments_WorkdayMeta {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  AttestedBy: WorkdaysWithComments_workdaysWithComments_WorkdayMeta_AttestedBy | null;
  Comments: WorkdaysWithComments_workdaysWithComments_WorkdayMeta_Comments[] | null;
}

export interface WorkdaysWithComments_workdaysWithComments {
  __typename: "Workday";
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  overTime: number | null;
  orderId: number;
  orderName: string;
  personId: number;
  WorkdayMeta: WorkdaysWithComments_workdaysWithComments_WorkdayMeta | null;
}

export interface WorkdaysWithComments {
  workdaysWithComments: WorkdaysWithComments_workdaysWithComments[];
}

export interface WorkdaysWithCommentsVariables {
  data: WorkdaysWithCommentsInput;
  limit?: number | null;
  cursor?: string | null;
  commentFilter: CommentFilter[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Workspaces
// ====================================================

export interface Workspaces_workspaces {
  __typename: "Workspace";
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
}

export interface Workspaces {
  workspaces: Workspaces_workspaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WorkspaceActiveWorkforce
// ====================================================

export interface WorkspaceActiveWorkforce_workspaceActiveWorkforce {
  __typename: "ActiveWorkforce";
  id: number;
  activeWorkers: number;
  name: string;
}

export interface WorkspaceActiveWorkforce {
  workspaceActiveWorkforce: WorkspaceActiveWorkforce_workspaceActiveWorkforce[];
}

export interface WorkspaceActiveWorkforceVariables {
  data: WorkspaceActiveWorkforceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CommentParts
// ====================================================

export interface CommentParts_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface CommentParts {
  __typename: "Comment";
  id: string;
  createdAt: any;
  updatedAt: any;
  text: string;
  resolved: boolean;
  Creator: CommentParts_Creator | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderParts
// ====================================================

export interface OrderParts_Workspace {
  __typename: "Workspace";
  id: string;
}

export interface OrderParts {
  __typename: "Order";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalOrderId: number;
  factor: number;
  name: string | null;
  customer: string;
  Workspace: OrderParts_Workspace | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserInviteParts
// ====================================================

export interface UserInviteParts {
  __typename: "UserInvite";
  id: string;
  createdAt: any;
  expiresAt: any;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserParts
// ====================================================

export interface UserParts {
  __typename: "User";
  id: string;
  createdAt: any;
  email: string;
  name: string | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isElevatedUser: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UsersParts
// ====================================================

export interface UsersParts_Workspaces {
  __typename: "Workspace";
  id: string;
}

export interface UsersParts {
  __typename: "User";
  id: string;
  createdAt: any;
  email: string;
  name: string | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isElevatedUser: boolean;
  Workspaces: UsersParts_Workspaces[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkdayMetaParts
// ====================================================

export interface WorkdayMetaParts_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayMetaParts_Comments {
  __typename: "Comment";
  text: string;
  id: string;
  createdAt: any;
  Creator: WorkdayMetaParts_Comments_Creator | null;
}

export interface WorkdayMetaParts_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayMetaParts {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  Comments: WorkdayMetaParts_Comments[] | null;
  AttestedBy: WorkdayMetaParts_AttestedBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkdayMetaWithCommentsParts
// ====================================================

export interface WorkdayMetaWithCommentsParts_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayMetaWithCommentsParts_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayMetaWithCommentsParts_Comments {
  __typename: "Comment";
  id: string;
  createdAt: any;
  updatedAt: any;
  text: string;
  resolved: boolean;
  Creator: WorkdayMetaWithCommentsParts_Comments_Creator | null;
}

export interface WorkdayMetaWithCommentsParts {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  AttestedBy: WorkdayMetaWithCommentsParts_AttestedBy | null;
  Comments: WorkdayMetaWithCommentsParts_Comments[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkdayParts
// ====================================================

export interface WorkdayParts_WorkdayMeta_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayParts_WorkdayMeta_Comments {
  __typename: "Comment";
  text: string;
  id: string;
  createdAt: any;
  Creator: WorkdayParts_WorkdayMeta_Comments_Creator | null;
}

export interface WorkdayParts_WorkdayMeta_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayParts_WorkdayMeta {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  Comments: WorkdayParts_WorkdayMeta_Comments[] | null;
  AttestedBy: WorkdayParts_WorkdayMeta_AttestedBy | null;
}

export interface WorkdayParts {
  __typename: "Workday";
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  scheduledStartTime: string | null;
  scheduledEndTime: string | null;
  reportedStartTime: string | null;
  reportedEndTime: string | null;
  deviation: WorkdayDeviation | null;
  overTime: number | null;
  orderId: number;
  orderName: string;
  isBossConfirmed: boolean | null;
  personId: number;
  WorkdayMeta: WorkdayParts_WorkdayMeta | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkdayWithCommentsParts
// ====================================================

export interface WorkdayWithCommentsParts_WorkdayMeta_AttestedBy {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayWithCommentsParts_WorkdayMeta_Comments_Creator {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface WorkdayWithCommentsParts_WorkdayMeta_Comments {
  __typename: "Comment";
  id: string;
  createdAt: any;
  updatedAt: any;
  text: string;
  resolved: boolean;
  Creator: WorkdayWithCommentsParts_WorkdayMeta_Comments_Creator | null;
}

export interface WorkdayWithCommentsParts_WorkdayMeta {
  __typename: "WorkdayMeta";
  id: string;
  createdAt: any;
  updatedAt: any;
  externalWorkdayId: number;
  attested: boolean;
  attestedById: string | null;
  CommentCount: number | null;
  AttestedBy: WorkdayWithCommentsParts_WorkdayMeta_AttestedBy | null;
  Comments: WorkdayWithCommentsParts_WorkdayMeta_Comments[] | null;
}

export interface WorkdayWithCommentsParts {
  __typename: "Workday";
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  overTime: number | null;
  orderId: number;
  orderName: string;
  personId: number;
  WorkdayMeta: WorkdayWithCommentsParts_WorkdayMeta | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceParts
// ====================================================

export interface WorkspaceParts {
  __typename: "Workspace";
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AttestFilter {
  ATTESTED = "ATTESTED",
  NOT_ATTESTED = "NOT_ATTESTED",
}

export enum CommentFilter {
  NOT_RESOLVED = "NOT_RESOLVED",
  RESOLVED = "RESOLVED",
}

export enum ReportType {
  HC = "HC",
  OVERTIME = "OVERTIME",
  TIMEBASEDHC = "TIMEBASEDHC",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum WorkdayCommentFilter {
  WITHOUT_COMMENTS = "WITHOUT_COMMENTS",
  WITH_COMMENTS = "WITH_COMMENTS",
}

export enum WorkdayDeviation {
  CHANGED_SCHEDULE = "CHANGED_SCHEDULE",
  MISSING_TIMESTAMP = "MISSING_TIMESTAMP",
}

export interface CreateCommentInput {
  externalWorkdayId: number;
  workspaceId: string;
  text: string;
}

export interface DeleteCommentInput {
  commentId: string;
}

export interface MassAttestWorkspaceInput {
  workspaceId: string;
  fromDate: string;
  toDate: string;
  noDeviation: boolean;
  noComments: boolean;
}

export interface OrdersOrderInput {
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  externalId?: SortOrder | null;
  factor?: SortOrder | null;
}

export interface UpdateCommentInput {
  id: string;
  text?: string | null;
  resolved?: boolean | null;
}

export interface UserInviteCreateInput {
  email: string;
  workspaceId: string;
}

export interface UserInviteDeleteInput {
  id: string;
}

export interface UserInviteRefreshInput {
  id: string;
  workspaceId: string;
}

export interface UserInvitesOrderInput {
  createdAt?: SortOrder | null;
}

export interface UserPrivilegeUpdateInput {
  userId: string;
  isAdmin?: boolean | null;
  isElevatedUser?: boolean | null;
}

export interface UserSignupInput {
  userInviteId: string;
  name?: string | null;
}

export interface UserWorkspaceUpdateInput {
  userId: string;
  workspaceId: string;
  connect: boolean;
}

export interface WorkdayMetaInput {
  workdayMetaId?: string | null;
  workspaceId: string;
  workdayId: number;
  attested: boolean;
}

export interface WorkdaysWithCommentsInput {
  workspaceId: string;
}

export interface WorkspaceActiveWorkforceInput {
  workspaceId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
