import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { DocumentNode } from 'graphql'
import React, {
  CSSProperties,
  Fragment,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { useInView } from 'react-intersection-observer'
import indexedArray from 'utils/indexedArray'

interface Props<T, V> {
  query: DocumentNode
  isInsideTbody?: boolean
  skipQuery?: boolean
  limit?: number
  variables?: Partial<V>
  className?: string
  style?: CSSProperties
  fetchMoreOnScroll?: boolean
  listEmptyMessage?: ReactNode | string
  fetchPolicy?: WatchQueryFetchPolicy
  nextFetchPolicy?: WatchQueryFetchPolicy
  fetchMoreButton?: ReactNode
  disableContainer?: boolean
  disableEmptyMessage?: boolean
  onListEmptyChange?: (empty: boolean) => void
  renderListItem: (item: T) => ReactNode
  renderLoadingListItem?: (index: number) => ReactNode
  onDataChange?: (items: T[]) => void
  onLoading?: (loading: boolean) => void
}

function PaginatedList<T extends { id: string | number }, V>({
  query,
  isInsideTbody,
  variables,
  limit = 10,
  className = '',
  style = {},
  renderListItem,
  renderLoadingListItem,
  fetchMoreOnScroll,
  listEmptyMessage,
  fetchMoreButton,
  disableContainer,
  skipQuery,
  fetchPolicy,
  onDataChange,
  onLoading,
  nextFetchPolicy,
  onListEmptyChange,
  disableEmptyMessage,
}: Props<T, V>) {
  const { data, previousData, loading, error, fetchMore } = useQuery(query, {
    variables: { ...(variables || {}), limit },
    skip: skipQuery,
    fetchPolicy: fetchPolicy || 'cache-first',
    nextFetchPolicy: nextFetchPolicy || 'cache-first',
  })

  const items = useMemo(
    () =>
      skipQuery
        ? []
        : data?.[Object.keys(data)?.[0]] ||
          previousData?.[Object.keys(previousData)?.[0]] ||
          [],
    [data, previousData, skipQuery],
  )

  useEffect(() => {
    onDataChange?.(items)
    // eslint-disable-next-line
  }, [items])

  useEffect(() => {
    onLoading?.(loading)
    // eslint-disable-next-line
  }, [loading])

  const [hasMore, setHasMore] = useState(true)
  useEffect(() => {
    if (loading) setHasMore(true)
  }, [loading])
  useEffect(() => {
    if (
      (!loading && items.length !== 0 && items.length % limit !== 0) ||
      (items.length === 0 && !loading && !skipQuery)
    ) {
      setHasMore(false)
    }
  }, [items, limit, loading, skipQuery])
  const [fetchingMore, setFetchingMore] = useState(false)

  const handleLoadMore = async () => {
    const cursor =
      (items?.length ?? 0) > 0 ? String(items[items.length - 1].id) : undefined
    if (!cursor && !fetchingMore) {
      return
    }

    setFetchingMore(true)
    try {
      const { data } = (await fetchMore({
        variables: {
          cursor,
        },
      })) as any
      if (
        data?.[Object.keys(data)?.[0]]?.length === 0 &&
        !skipQuery &&
        !loading
      ) {
        setHasMore(false)
      }
      setFetchingMore(false)
    } catch {
      toast.error('Någonting gick fel när mer data skulle hämtas.')
      setFetchingMore(false)
    }
  }

  const { ref, inView } = useInView({})
  useEffect(() => {
    if (
      inView &&
      items.length !== 0 &&
      !loading &&
      !fetchingMore &&
      hasMore &&
      !error
    ) {
      handleLoadMore()
    }
    // eslint-disable-next-line
  }, [inView, loading, fetchingMore])

  useLayoutEffect(() => {
    if (!loading && !skipQuery) {
      onListEmptyChange?.(items.length === 0)
    }
    // eslint-disable-next-line
  }, [items, loading, skipQuery])

  const content = (
    <>
      {items.map((item: T, index: number) => renderListItem(item))}
      {items.length === 0 &&
        renderLoadingListItem &&
        loading &&
        indexedArray(limit).map((index) => (
          <Fragment key={index}>{renderLoadingListItem(index)}</Fragment>
        ))}
      {items.length === 0 && !loading && !disableEmptyMessage && (
        <>
          {typeof listEmptyMessage === 'string' || !listEmptyMessage ? (
            <div className='text-sm text-gray-500 flex-center p-2'>
              {listEmptyMessage || 'Ingen data hittades.'}
            </div>
          ) : (
            listEmptyMessage
          )}
        </>
      )}
      {hasMore &&
        fetchMoreOnScroll &&
        (isInsideTbody ? (
          <tr className='w-px h-px flex-none' ref={ref} />
        ) : (
          <div className='w-px h-px flex-none' ref={ref} />
        ))}
      {hasMore && fetchMoreButton && !loading && (
        <div onClick={handleLoadMore}>{fetchMoreButton}</div>
      )}
    </>
  )

  if (disableContainer) {
    return content
  }

  return (
    <div className={className} style={style}>
      {content}
    </div>
  )
}

export default PaginatedList
