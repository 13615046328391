import { getAuth } from '@firebase/auth'
import { Menu, Popover, Transition } from '@headlessui/react'
import { motion } from 'framer-motion'
import { generateProfileImage } from 'generateProfileImage'
import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoCloseOutline as XIcon,
  IoMenuOutline as MenuIcon,
} from 'react-icons/io5'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import WorkspaceSelector from './WorkspaceSelector'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const DashboardBase: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const params = useParams()
  const { firebaseUser, me } = useAuth()
  const location = useLocation()

  const user = {
    name: me?.name ?? '',
    email: me?.email ?? '',
    imageUrl:
      firebaseUser?.photoURL ??
      generateProfileImage(me?.name || me?.email || 'Anonymous'),
  }

  const navigation = [
    {
      name: t('DashboardBase.home'),
      href: '/',
      current: params['*'] === '',
      workspaceOnly: true,
      onlyAdmin: false,
      onlyElevatedUserOrAdmin: false,
    },
    {
      name: t('DashboardBase.report'),
      href: '/report',
      workspaceOnly: true,
      onlyElevatedUserOrAdmin: true,
      onlyAdmin: false,
    },
    {
      name: t('DashboardBase.settings'),
      href: '/settings',
      current: params['*'] === 'settings',
      workspaceOnly: true,
      onlyAdmin: true,
      onlyElevatedUserOrAdmin: false,
    },
    {
      name: t('DashboardBase.user_invitations'),
      href: '/userInvites',
      workspaceOnly: true,
      onlyAdmin: true,
      onlyElevatedUserOrAdmin: false,
    },
    {
      name: t('DashboardBase.users'),
      href: '/users',
      workspaceOnly: false,
      onlyAdmin: true,
      onlyElevatedUserOrAdmin: false,
    },
  ]

  const userNavigation: { name: string; onClick: () => any; href?: string }[] =
    [
      {
        name: t('DashboardBase.user_signout'),
        onClick: async () => {
          const auth = getAuth()
          await auth.signOut()
        },
      },
    ]
  const renderMenuItem = useCallback(
    (item: typeof navigation[number]) => {
      const active = location.pathname === item.href
      const isAdmin = me?.isAdmin || me?.isSuperAdmin
      const isElevatedUserOrAdmin = isAdmin || me?.isElevatedUser
      let shouldRender =
        (!item?.onlyAdmin && !item.onlyElevatedUserOrAdmin) ||
        (isAdmin && item?.onlyAdmin) ||
        (isElevatedUserOrAdmin && item?.onlyElevatedUserOrAdmin)

      return shouldRender ? (
        <div key={item.name} className='relative grid'>
          {active && (
            <motion.div
              layoutId='menuItemBg'
              className=' rounded-md bg-gray-100 p-2 col-span-full row-span-full z-0'
            />
          )}
          <Link
            to={item.href}
            className={classNames(
              'text-gray-700 flex items-center font-semibold text-sm  rounded-md bg-gray-100 bg-opacity-0 px-3 py-2 hover:bg-opacity-10 col-span-full row-span-full z-10',
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </Link>
        </div>
      ) : null
    },
    [location.pathname, me?.isAdmin, me?.isElevatedUser, me?.isSuperAdmin],
  )
  const adminNavigationItems = navigation
    .filter(
      (item) =>
        item.onlyAdmin &&
        (me?.isAdmin || me?.isSuperAdmin) &&
        !item.workspaceOnly,
    )
    .map(renderMenuItem)
  const workspaceNavigationItems = navigation
    .filter((item) => item.workspaceOnly)
    .map(renderMenuItem)
  return (
    <>
      <div className='min-h-full' data-testid='dashboard-base'>
        <Popover as='header' className='lg:pb-24 pb-16'>
          {({ open }) => (
            <>
              <div className='bg-white shadow-sm mx-auto px-4 sm:px-6 py-2 lg:max-w-full lg:px-8 mb-10'>
                <div className='relative py-5 lg:py-2 flex items-center justify-center lg:justify-between lg:my-0'>
                  {/* Logo */}
                  <div className='absolute left-0 flex-shrink-0 lg:static'>
                    <Link to='/'>
                      <span className='sr-only'>Lernia logo</span>
                      <img
                        className='h-8 w-auto'
                        src='/lernia-logo.png'
                        alt='Workflow'
                      />
                    </Link>
                  </div>
                  <div className='flex-1 justify-start  lg:block hidden ml-10'>
                    <div className='col-span-2'>
                      <nav className='flex space-x-4'>
                        {workspaceNavigationItems.length ? (
                          <div
                            className={`${
                              adminNavigationItems.length
                                ? 'border-t border-l border-r'
                                : ''
                            } border-green-400 rounded flex  relative`}
                          >
                            {Boolean(adminNavigationItems.length) && (
                              <div className='absolute left-0 right-0 mx-auto -top-4 text-gray-500 inline-table text-xs'>
                                {t('DashboardBase.workspace_navigation')}
                              </div>
                            )}
                            {workspaceNavigationItems}
                          </div>
                        ) : null}
                        {adminNavigationItems.length ? (
                          <div className='border-t border-gray-400 border-l border-r rounded flex  relative'>
                            <div className='absolute left-0 right-0 mx-auto -top-4 text-gray-500 inline-table text-xs'>
                              Admin
                            </div>
                            {adminNavigationItems}
                          </div>
                        ) : null}
                      </nav>
                    </div>
                  </div>

                  {/* Right section on desktop */}
                  <div className='hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5'>
                    <span className='sr-only'>View notifications</span>
                    <div className='w-60'>
                      <WorkspaceSelector />
                    </div>

                    {/* Profile dropdown */}
                    <Menu as='div' className='ml-4 relative flex-shrink-0'>
                      <div>
                        <Menu.Button className='bg-white rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100'>
                          <span className='sr-only'>Open user menu</span>
                          <img
                            className='h-8 w-8 rounded-full border border-gray-200'
                            src={user.imageUrl}
                            alt=''
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-4 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <div className='flex items-center px-5 mb-4'>
                            <div className='min-w-0 flex-1'>
                              <div className='text-base font-medium text-gray-800 truncate'>
                                {user.name}
                              </div>
                              <div className='text-sm font-medium text-gray-500 truncate'>
                                {user.email}
                              </div>
                            </div>
                            <button
                              type='button'
                              className='ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            >
                              <span className='sr-only'>
                                View notifications
                              </span>
                            </button>
                          </div>
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) =>
                                item.href ? (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700',
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                ) : (
                                  <button
                                    onClick={item.onClick}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700 w-full text-left',
                                    )}
                                  >
                                    {item.name}
                                  </button>
                                )
                              }
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {/* Menu button */}
                  <div className='absolute right-0 flex-shrink-0 lg:hidden'>
                    {/* Mobile menu button */}
                    <Popover.Button
                      data-testid='mobile-menu-button'
                      className='bg-transparent p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white'
                    >
                      <span className='sr-only'>Open main menu</span>
                      {open ? (
                        <XIcon className='block h-6 w-6' aria-hidden='true' />
                      ) : (
                        <MenuIcon
                          className='block h-6 w-6'
                          aria-hidden='true'
                        />
                      )}
                    </Popover.Button>
                  </div>
                </div>
              </div>

              <Transition.Root as={Fragment}>
                <div className='lg:hidden'>
                  <Transition.Child
                    as={Fragment}
                    enter='duration-150 ease-out'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='duration-150 ease-in'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Popover.Overlay
                      data-testid='mobile-menu-overlay'
                      className='z-20 fixed inset-0 bg-black bg-opacity-25'
                    />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter='duration-150 ease-out'
                    enterFrom='opacity-0 scale-95'
                    enterTo='opacity-100 scale-100'
                    leave='duration-150 ease-in'
                    leaveFrom='opacity-100 scale-100'
                    leaveTo='opacity-0 scale-95'
                  >
                    <Popover.Panel
                      focus
                      data-testid='mobile-menu'
                      className='z-30 absolute top-0 inset-x-0 max-w-[90%] mx-auto w-full p-2 transition transform origin-top'
                    >
                      {({ close }) => (
                        <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200'>
                          <div className='pt-3 pb-2'>
                            <div className='flex items-center justify-between px-4'>
                              <div>
                                <img
                                  className='h-8 w-auto'
                                  src='/lernia-logo.png'
                                  alt='Workflow'
                                />
                              </div>
                              <div className='-mr-2'>
                                <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                                  <span className='sr-only'>Close menu</span>
                                  <XIcon
                                    className='h-6 w-6'
                                    aria-hidden='true'
                                  />
                                </Popover.Button>
                              </div>
                            </div>
                            <div className='mt-3 px-2 space-y-1'>
                              {navigation.map((nav) => (
                                <Link
                                  key={nav.name}
                                  to={nav.href}
                                  onClick={() => close()}
                                  className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                                >
                                  {nav.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                          <WorkspaceSelector borderless />
                          <div className='pt-4 pb-2'>
                            <div className='flex items-center px-5'>
                              <div className='flex-shrink-0'>
                                <img
                                  className='h-10 w-10 rounded-full border border-gray-200'
                                  src={user.imageUrl}
                                  alt=''
                                />
                              </div>
                              <div className='ml-3 min-w-0 flex-1'>
                                <div className='text-base font-medium text-gray-800 truncate'>
                                  {user.name}
                                </div>
                                <div className='text-sm font-medium text-gray-500 truncate'>
                                  {user.email}
                                </div>
                              </div>
                            </div>
                            <div className='mt-3 px-2 space-y-1'>
                              {userNavigation.map((item) =>
                                item.href ? (
                                  <Link
                                    key={item.name}
                                    to={item.href}
                                    className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                                  >
                                    {item.name}
                                  </Link>
                                ) : (
                                  <button
                                    key={item.name}
                                    onClick={item.onClick}
                                    className='block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 w-full text-left'
                                  >
                                    {item.name}
                                  </button>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
            </>
          )}
        </Popover>
        <main className='-mt-24 pb-8'>
          <div className='max-w-[95%] mx-auto px-4 sm:px-6 lg:max-w-full lg:px-8'>
            {/* Main 3 column grid */}
            <div className='grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8'>
              {children}
            </div>
          </div>
        </main>
        <footer>
          <div className='mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left'>
              <span className='block sm:inline'>
                &copy; 2023 Flexime Technologies AB.
              </span>{' '}
              <span className='block sm:inline'>All rights reserved.</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default DashboardBase
