import { DocumentNode } from '@apollo/client'
import noDataImg from 'assets/noData.svg'
import { SortOrder } from 'generatedTypes'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { MdArrowDownward, MdUnfoldMore } from 'react-icons/md'
import indexedArray from 'utils/indexedArray'
import PaginatedList from './PaginatedList'
import TableRowActionButton from './TableRowActionButton'

export interface TableRowAction<T> {
  icon?: ReactNode
  title: string
  onClick: (row: T) => void
  renderCondition?: (row: T) => boolean
}

export interface TableColumn<T> {
  id: string
  title: string
  sortable?: boolean
  className?: (data: any) => string
  formatter?: (data: any, row: T) => ReactNode | string
}

interface Props<T, V> {
  query: DocumentNode
  skipQuery?: boolean
  columns: TableColumn<T>[]
  variables?: Partial<V>
  initialOrderBy?: { [key: string]: SortOrder }
  rowActions?: TableRowAction<T>[]
  onRowClick?: (data: T) => void
}

function Table<T extends { id: string | number }, V>({
  query,
  skipQuery = false,
  columns,
  variables: variables_,
  onRowClick,
  rowActions,
  initialOrderBy,
}: Props<T, V>) {
  const [orderBy, setOrderBy] = useState<
    { [key: string]: SortOrder } | undefined
  >(initialOrderBy)

  const tableHeader = useMemo(() => {
    return (
      <tr>
        {columns.map((col) => {
          const toggleSortOrder = () => {
            if (!col.sortable) {
              return
            }

            if (orderBy?.[col.id] && orderBy?.[col.id] === SortOrder.asc) {
              setOrderBy({ [col.id]: SortOrder.desc })
            } else if (
              orderBy?.[col.id] &&
              orderBy?.[col.id] === SortOrder.desc
            ) {
              setOrderBy({})
            } else {
              setOrderBy({ [col.id]: SortOrder.asc })
            }
          }

          return (
            <th
              scope='col'
              onClick={toggleSortOrder}
              className={`px-6 py-4 text-left text-xs font-medium text-gray-500 tracking-wider ${
                col.sortable ? 'cursor-pointer' : ''
              }`}
              key={col.id}
            >
              <div className='flex items-center truncate'>
                {col.title}
                {col.sortable && !orderBy?.[col.id] && (
                  <MdUnfoldMore className='ml-2' />
                )}
                {col.sortable && orderBy?.[col.id] && (
                  <MdArrowDownward
                    className={`
                      ml-2 transform transition-transform duration-200 
                      ${
                        orderBy?.[col.id] === SortOrder.desc
                          ? 'rotate-0'
                          : 'rotate-180'
                      }
                    `}
                  />
                )}
              </div>
            </th>
          )
        })}
        {rowActions && <th className='w-12' />}
      </tr>
    )
  }, [columns, orderBy, rowActions])

  const renderRow = useCallback(
    (row: T) => {
      return (
        <tr
          key={row.id}
          className={`${onRowClick ? 'cursor-pointer hover:bg-gray-50' : ''}`}
        >
          {columns.map((col, index) => (
            <td
              key={col.id}
              className={`px-6 py-5 max-w-md truncate text-sm ${
                index === 0 ? 'text-gray-900' : 'text-gray-500'
              } ${col.className ? col.className(row) : ''}`}
              onClick={() => onRowClick?.(row)}
            >
              {col.formatter
                ? col.formatter((row as any)?.[col.id], row)
                : (row as any)?.[col.id]}
            </td>
          ))}
          {rowActions && (
            <td>
              <TableRowActionButton actions={rowActions} row={row} />
            </td>
          )}
        </tr>
      )
    },
    [columns, onRowClick, rowActions],
  )

  const renderLoadingRow = useCallback(
    (index: number) => {
      return (
        <tr key={index}>
          {columns.map((col) => (
            <td key={col.id} className='px-6 py-5 whitespace-nowrap text-sm'>
              <div className='w-full h-2 bg-slate-200 rounded animate-pulse py-[10px]' />
            </td>
          ))}
          {rowActions && <td className='px-6 py-5 whitespace-nowrap text-sm' />}
        </tr>
      )
    },
    [columns, rowActions],
  )

  const variables = useMemo(
    () => ({
      ...(variables_ || {}),
      orderBy,
    }),
    [variables_, orderBy],
  )

  const [tableEmpty, setTableEmpty] = useState<boolean>(false)

  return (
    <div className='shadow overflow-hidden rounded-lg overflow-x-auto relative'>
      <div className='sm:flex sm:flex-col block'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>{tableHeader}</thead>

          <tbody className='bg-white divide-y divide-gray-100 relative'>
            <PaginatedList
              isInsideTbody={true}
              query={query}
              skipQuery={skipQuery}
              variables={variables}
              renderListItem={renderRow}
              renderLoadingListItem={renderLoadingRow}
              fetchMoreOnScroll
              disableEmptyMessage
              onListEmptyChange={setTableEmpty}
              disableContainer
            />
            {(tableEmpty || skipQuery) &&
              indexedArray(10).map((index) => (
                <tr key={index}>
                  {columns.map((col) => (
                    <td
                      key={col.id}
                      className='px-6 py-5 whitespace-nowrap text-sm'
                    >
                      {skipQuery && (
                        <div className='w-full h-2 bg-slate-200 rounded animate-pulse py-[10px]' />
                      )}
                    </td>
                  ))}
                  {rowActions && (
                    <td className='px-6 py-5 whitespace-nowrap text-sm' />
                  )}
                </tr>
              ))}
            {tableEmpty && (
              <tr className='absolute top-0 pt-10 w-full h-full'>
                <td className='sticky left-0 right-0  flex-center flex-col w-screen sm:w-auto'>
                  <img
                    className='w-[160px] opacity-70'
                    src={noDataImg}
                    alt='Ingen data'
                  />
                  <div className='mt-6 text-gray-700 text-base text-center'>
                    Ingen data hittades
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
