import { gql } from '@apollo/client'
import WorkdayMetaWithCommentsParts from './WorkdayMetaWithCommentsParts'

export default gql`
  fragment WorkdayWithCommentsParts on Workday {
    id
    name
    startTime
    endTime
    overTime

    orderId
    orderName

    personId

    WorkdayMeta {
      id
      ...WorkdayMetaWithCommentsParts
    }
  }
  ${WorkdayMetaWithCommentsParts}
`
