import { useMutation } from '@apollo/client'
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth'
import { FC, useRef } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  CreateUser,
  CreateUserVariables,
  UserInvite_userInvite,
} from '../generatedTypes'
import { CreateUserMutation } from '../graphql/mutations/User'

type RegisterType = {
  email: string
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
}

interface SignUpPageProps {
  userInvite: UserInvite_userInvite
  setSigningUp: (signingUp: boolean) => void
}

const SignUpWithEmail: FC<SignUpPageProps> = ({ userInvite, setSigningUp }) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegisterType>()
  const password = useRef({})
  password.current = watch('password', '')

  const [createUser] = useMutation<CreateUser, CreateUserVariables>(
    CreateUserMutation,
  )
  const navigate = useNavigate()

  const handleSignUpWithEmail = async (values: RegisterType) => {
    const auth = getAuth()

    try {
      setSigningUp(true)
      if (!userInvite) throw new Error('No user invite')

      const data = {
        name: `${values.firstName} ${values.lastName}`,
        userInviteId: userInvite.id,
      }

      const credentials = await createUserWithEmailAndPassword(
        auth,
        userInvite.email,
        values.password,
      )
      await updateProfile(credentials.user, { displayName: data.name })

      await createUser({ variables: { data } })
      await sendEmailVerification(credentials.user)
      navigate('/verifyEmail')
    } catch (e) {
      console.error(e)
      toast.error(t('SignUpWithEmail.error'), {
        duration: 5000,
      })
    } finally {
      setSigningUp(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSignUpWithEmail)} className='space-y-6'>
      <div>
        <label
          htmlFor='email'
          className='block text-sm font-medium text-gray-700'
        >
          {t('SignUpWithEmail.email_adress')}
        </label>

        <div className='mt-1'>
          <input
            id='email'
            value={userInvite.email ?? ''}
            {...register('email', {
              required: t('SignUpWithEmail.email_adress_is_required') as string,
              value: userInvite.email ?? '',
            })}
            disabled
            type='email'
            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>
        {errors.email && (
          <p className='block text-red-600 text-sm font-medium'>
            {errors.email.message}
          </p>
        )}
      </div>

      <div>
        <label
          htmlFor='firstName'
          className='block text-sm font-medium text-gray-700'
        >
          {t('SignUpWithEmail.first_name')}
        </label>

        <div className='mt-1'>
          <input
            id='first-name'
            {...register('firstName')}
            type='first-name'
            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>
      </div>

      <div>
        <label
          htmlFor='lastName'
          className='block text-sm font-medium text-gray-700'
        >
          {t('SignUpWithEmail.last_name')}
        </label>

        <div className='mt-1'>
          <input
            id='last-name'
            {...register('lastName')}
            type='last-name'
            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>
      </div>

      <div>
        <label
          htmlFor='password'
          className='block text-sm font-medium text-gray-700'
        >
          {t('SignUpWithEmail.password')}
        </label>

        <div className='mt-1'>
          <input
            {...register('password', {
              required: true,
              minLength: {
                value: 8,
                message: t('SignUpWithEmail.password_min_length'),
              },
            })}
            id='password'
            type='password'
            name='password'
            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>

        {errors.password && (
          <p className='block text-red-600 text-sm font-medium'>
            {errors.password.message}
          </p>
        )}
      </div>

      <div>
        <label
          htmlFor='password'
          className='block text-sm font-medium text-gray-700'
        >
          {t('SignUpWithEmail.confirm_password')}
        </label>
        <div className='mt-1'>
          <input
            {...register('confirmPassword', {
              required: true,
              validate: (value) => {
                return (
                  value === password.current ||
                  (t('SignUpWithEmail.passwords_do_not_match') as string)
                )
              },
            })}
            type='password'
            name='confirmPassword'
            id='confirmPassword'
            className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>
        {errors.confirmPassword && (
          <p className='block text-red-600 text-sm font-medium'>
            {errors.confirmPassword.message}
          </p>
        )}
      </div>

      <input
        value={t('SignUpWithEmail.sign_up_with_email') as string}
        type='submit'
        className='w-full flex justify-center cursor-pointer py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      />
    </form>
  )
}

export default SignUpWithEmail
