import React, { FC } from 'react'

interface DashboardBaseProps {
  loading: boolean
}
const DashboardBase: FC<DashboardBaseProps> = ({ children, loading }) => {
  return (
    <div className={`min-h-screen ${!loading ? 'bg-[#EF7D00]' : ''}`}>
      <main className='w-full mx-auto pb-10 lg:pb-12 px-4'>
        <div className='w-full flex justify-end pt-4'>
          <img
            className='h-10 w-auto'
            src='/lernia-logo-white.png'
            alt='Workflow'
          />
        </div>
        <div className='flex justify-center'>
          <div className='flex-col space-y-6 sm:px-6 lg:px-0 w-full'>
            {children}
          </div>
        </div>
      </main>
    </div>
  )
}

export default DashboardBase
