import { gql } from '@apollo/client'

export default gql`
  fragment CommentParts on Comment {
    id
    createdAt
    updatedAt

    text
    resolved

    Creator {
      id
      name
    }
  }
`
