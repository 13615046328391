import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Spinner from 'base-components/Spinner'
import * as firebaseAuth from 'firebase/auth'
import { ParsedToken } from 'firebase/auth'
import {
  OrdersVariables,
  UserInvitesVariables,
  WorkdaysVariables,
} from 'generatedTypes'
import jwt_decode from 'jwt-decode'
import ReportPage from 'pages/ReportPage'
import UsersPage from 'pages/UsersPage'
import { WorkspaceProvider } from 'providers/WorkspaceProvider'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import cursorBasedPagination from 'utils/cursorBasePagination'
import DashboardBase from './components/DashboardBase'
import UnauthorizedLayout from './components/UnauthorizedLayout'
import { firebaseInit } from './init/firebaseInit'
import Home from './pages/Home'
import ResetPasswordPage from './pages/ResetPasswordPage'
import SettingsPage from './pages/SettingsPage'
import SignIn from './pages/SignIn'
import SignUpPage from './pages/SignUpPage'
import UserInvitesPage from './pages/UserInvitesPage'
import VerifyEmailPage from './pages/VerifyEmailPage'
import { AuthProvider, useAuth } from './providers/AuthProvider'

firebaseInit()

const authLink = setContext(async (_, { headers }) => {
  let shouldForceRefresh = false
  try {
    const decodedToken: ParsedToken = jwt_decode(
      (await firebaseAuth.getAuth().currentUser?.getIdToken()) ?? '',
    )
    if (
      !decodedToken.email_verified &&
      decodedToken?.firebase?.sign_in_provider === 'password'
    ) {
      shouldForceRefresh = true
    }
  } catch (err) {}
  const idToken = await firebaseAuth
    .getAuth()
    .currentUser?.getIdToken(shouldForceRefresh)
  return {
    headers: {
      ...headers,
      authorization: idToken && `Bearer ${idToken}`,
    },
  }
})

const httpLink = createHttpLink({ uri: process.env.REACT_APP_BACKEND_URL })

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  connectToDevTools: process.env.NODE_ENV !== 'production',
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          userInvites: cursorBasedPagination<UserInvitesVariables>([
            'orderBy',
            'workspaceId',
          ]),
          workdays: cursorBasedPagination<WorkdaysVariables>([
            'workspaceId',
            'orderIds',
            'attestFilter',
            'commentFilter',
            'deviationFilter',
            'punchedInFilter',
            'dateFrom',
            'dateTo',
          ]),
          orders: cursorBasedPagination<OrdersVariables>([
            'orderBy',
            'workspaceId',
          ]),
          workdaysWithComments: cursorBasedPagination([
            'workspaceId',
            'commentFilter',
          ]),
        },
      },
    },
  }),
})

const App = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <Toaster />
          <Authentication />
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

export default App

const Authentication = () => {
  const auth = useAuth()
  if (auth.loading) {
    return (
      <UnauthorizedLayout loading={auth.loading}>
        <div className='w-full h-full flex justify-center items-centers animate-ping'>
          <Spinner />
        </div>
      </UnauthorizedLayout>
    )
  }
  return !auth.isAuthenticated ? (
    <UnauthorizedLayout loading={auth.loading}>
      <Routes>
        <Route path='/invite/:inviteId' element={<SignUpPage />} />
        <Route path='/verifyEmail' element={<VerifyEmailPage />} />
        <Route path='/resetPassword' element={<ResetPasswordPage />} />
        <Route path='/signin' element={<SignIn />} />
        <Route path='*' element={<Navigate to='/signin' />} />
      </Routes>
    </UnauthorizedLayout>
  ) : (
    <Routes>
      <Route path='/*' element={<Authorized />} />
    </Routes>
  )
}
const Authorized = () => {
  return (
    <WorkspaceProvider>
      <DashboardBase>
        <Routes>
          <Route path='/settings' element={<SettingsPage />} />
          <Route path='users' element={<UsersPage />} />
          <Route path='userInvites' element={<UserInvitesPage />} />
          <Route path='report' element={<ReportPage />} />
          <Route path='/' element={<Home />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </DashboardBase>
    </WorkspaceProvider>
  )
}
