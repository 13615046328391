import { gql } from '@apollo/client'

export const CreateReportMutation = gql`
  mutation CreateReport(
    $workspaceId: String!
    $dateFrom: Date!
    $dateTo: Date!
    $type: ReportType!
  ) {
    createReport(
      workspaceId: $workspaceId
      dateFrom: $dateFrom
      dateTo: $dateTo
      type: $type
    )
  }
`
