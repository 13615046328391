import { gql } from '@apollo/client'
import WorkdayParts from 'graphql/fragments/WorkdayParts'

export const UpdateWorkdayMetaMutation = gql`
  mutation UpdateWorkdayMeta($data: WorkdayMetaInput!) {
    updateWorkdayMeta(data: $data) {
      id
      ...WorkdayParts
    }
  }
  ${WorkdayParts}
`
