import { useQuery } from '@apollo/client'
import { getApp } from 'firebase/app'
import { getAuth, User } from 'firebase/auth'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Me, UserParts } from '../generatedTypes'
import { MeQuery } from '../graphql/queries/User'
import ErrorPage from '../pages/ErrorPage'

export interface AuthProviderValue {
  loading: boolean
  firebaseUser?: User
  me?: UserParts
  isAuthenticated: boolean
}

const AuthContext = createContext<AuthProviderValue>({
  loading: true,
  firebaseUser: undefined,
  me: undefined,
  isAuthenticated: false,
})
const useAuth = () => useContext(AuthContext)

const AuthProvider: FC = ({ children }) => {
  const { t } = useTranslation()
  const [firebaseUser, setFirebaseUser] = useState<undefined | User>()
  const [loadingFirebaseUser, setLoadingFirebaseUser] = useState(true)

  useEffect(() => {
    const app = getApp()
    const auth = getAuth(app)

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const hasEmailProvider = user.providerData.find(
          (provider) => provider.providerId === 'password',
        )

        if (hasEmailProvider && !user.emailVerified) {
          toast.error(t('AuthProvider.verified_error'), { id: 'test' })
        }

        setFirebaseUser(user)
      } else {
        setFirebaseUser(undefined)
      }
      setLoadingFirebaseUser(false)
    })

    return () => {
      unsubscribe()
    }
  })

  const {
    data: meData,
    loading: loadingMe,
    error: meError,
  } = useQuery<Me>(MeQuery, {
    skip:
      !firebaseUser ||
      (firebaseUser?.providerData?.[0]?.providerId === 'password' &&
        !firebaseUser.emailVerified),
    notifyOnNetworkStatusChange: true,
  })
  const me = meData?.me || undefined

  const loading = loadingFirebaseUser || loadingMe
  const isAuthenticated = Boolean(firebaseUser) && Boolean(me)

  const error = meError
  if (error) {
    return <ErrorPage error={error} />
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        firebaseUser,
        me,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuth }
