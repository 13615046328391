import { ApolloError, useMutation } from '@apollo/client'
import DeleteUserInviteModal from 'components/DeleteUserInviteModal'
import Table, { TableColumn, TableRowAction } from 'components/Table'
import { format, formatDistance, isAfter } from 'date-fns'
import { getDateLocale } from 'init/i18n'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { MdOutlineContentCopy } from 'react-icons/md'
import {
  CreateUserInvite,
  CreateUserInviteVariables,
  RefreshUserInvite,
  RefreshUserInviteVariables,
  SortOrder,
  UserInviteParts,
  UserInvitesVariables,
  UserInvite_userInvite,
} from '../generatedTypes'
import {
  CreateUserInviteMutation,
  RefreshUserInviteMutation,
} from '../graphql/mutations/UserInvite'
import { UserInvitesQuery } from '../graphql/queries/UserInvite'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const UserInvitesPage: React.FC = () => {
  const [createInvite] = useMutation<
    CreateUserInvite,
    CreateUserInviteVariables
  >(CreateUserInviteMutation)
  const { register, handleSubmit, reset } = useForm<{ email: string }>()
  const [refreshInvite] = useMutation<
    RefreshUserInvite,
    RefreshUserInviteVariables
  >(RefreshUserInviteMutation, { refetchQueries: ['UserInvites'] })
  const [deleteInvite, setDeleteInvite] = useState<{
    id: string
    email: string
  } | null>(null)
  const { t, i18n } = useTranslation()
  const { selectedWorkspace } = useWorkspace()
  const handleClick = async ({ email }: { email: string }) => {
    const toastId = toast.loading(t('UserInvitesPage.creatingInvite'))
    try {
      if (selectedWorkspace) {
        await createInvite({
          variables: {
            data: { email: email, workspaceId: selectedWorkspace.id },
          },
          refetchQueries: ['UserInvites'],
        })
        toast.success(t('UserInvitesPage.inviteCreated'), { id: toastId })
      }
    } catch (err) {
      ;(err as ApolloError).graphQLErrors.forEach((error) => {
        if (
          error.extensions.code === 'BAD_USER_INPUT' &&
          error.extensions.reason === 'duplicate'
        ) {
          toast.error(t('UserInvitesPage.createErrorDuplicate'), {
            id: toastId,
          })
        }
      })
      toast.error(t('UserInvitesPage.createError'), { id: toastId })
    }
    reset()
  }
  const tableColumns = useMemo<TableColumn<UserInvite_userInvite>[]>(() => {
    return [
      { id: 'email', title: t('UserInvitesPage.email') },
      {
        id: 'createdAt',
        title: t('UserInvitesPage.createdAt'),
        sortable: true,
        formatter: (date) => format(new Date(date), 'yyyy-MM-dd HH:mm'),
      },
      {
        id: 'id',
        title: t('UserInvitesPage.inviteLink'),
        formatter: (id) => (
          <CopyToClipboard
            text={window.location.origin + '/invite/' + id}
            onCopy={() => toast.success(t('UserInvitesPage.inviteLinkCopied'))}
          >
            <button className='flex flex-row items-center gap-2 p-1 rounded border border-gray-100 hover:bg-gray-100'>
              <MdOutlineContentCopy /> {t('UserInvitesPage.copy')}
            </button>
          </CopyToClipboard>
        ),
      },
      {
        id: 'expiresAt',
        title: t('UserInvitesPage.expiresAt'),
        formatter: (date) =>
          isAfter(new Date(), new Date(date))
            ? t('UserInvitesPage.expired')
            : t('UserInvitesPage.expiresIn', {
                time: formatDistance(new Date(date), new Date(), {
                  addSuffix: true,
                  locale: getDateLocale(i18n.language),
                }),
              }),
      },
    ]
  }, [i18n.language, t])
  const rowActions = useMemo<TableRowAction<UserInviteParts>[]>(
    () => [
      {
        title: t('UserInvitesPage.delete'),
        onClick: (userInvite) => setDeleteInvite(userInvite),
      },
      {
        title: t('UserInvitesPage.refresh'),
        onClick: async ({ id }) => {
          const toastId = toast.loading(t('UserInvitesPage.refreshing'))
          try {
            if (selectedWorkspace) {
              await refreshInvite({
                variables: { data: { id, workspaceId: selectedWorkspace.id } },
              })
            }
            toast.success(t('UserInvitesPage.refreshSuccess'), { id: toastId })
          } catch (err) {
            toast.error(t('UserInvitesPage.refreshError'), { id: toastId })
          }
        },
      },
    ],
    [refreshInvite, selectedWorkspace, t],
  )

  return (
    <>
      <div
        className='grid grid-cols-1 gap-4 lg:col-span-2 order-2 lg:order-1'
        data-testid='userInvites-route'
      >
        <section aria-labelledby='section-1-title'>
          <Table<UserInviteParts, UserInvitesVariables>
            columns={tableColumns}
            query={UserInvitesQuery}
            rowActions={rowActions}
            initialOrderBy={{ createdAt: SortOrder.desc }}
            skipQuery={!Boolean(selectedWorkspace?.id)}
            variables={{ workspaceId: selectedWorkspace?.id }}
          />
        </section>
      </div>
      <div className='grid grid-cols-1 gap-4 lg:col-span-1 order-1 lg:order-2'>
        <section aria-labelledby='section-1-title'>
          <div className='rounded-lg bg-white overflow-hidden shadow'>
            <div className='p-6'>
              <h2 className='mb-4'>{t('UserInvitesPage.createInvite')}</h2>
              <form onSubmit={handleSubmit(handleClick)}>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                >
                  {t('UserInvitesPage.email')}
                </label>

                <input
                  id='email'
                  className='mb-2 appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  type='email'
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                />
                <input
                  type='submit'
                  value={t('UserInvitesPage.submit') as string}
                  className=' flex justify-center cursor-pointer py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                />
              </form>
            </div>
          </div>
        </section>
      </div>
      <div className='order-3'>
        <DeleteUserInviteModal
          open={Boolean(deleteInvite)}
          userInvite={deleteInvite}
          onClose={() => setDeleteInvite(null)}
        />
      </div>
    </>
  )
}

export default UserInvitesPage
