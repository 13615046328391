import { gql } from '@apollo/client'
import CommentParts from 'graphql/fragments/CommentParts'
import WorkdayParts from 'graphql/fragments/WorkdayParts'

export const CreateCommentMutation = gql`
  mutation CreateComment($data: CreateCommentInput!) {
    createComment(data: $data) {
      id
      ...WorkdayParts
    }
  }
  ${WorkdayParts}
`

export const DeleteCommentMutation = gql`
  mutation DeleteComment($data: DeleteCommentInput!) {
    deleteComment(data: $data) {
      id
      ...CommentParts
    }
  }
  ${CommentParts}
`

export const UpdateCommentMutation = gql`
  mutation updateCommentMutation($data: UpdateCommentInput!) {
    updateComment(data: $data) {
      id
      ...CommentParts
    }
  }
  ${CommentParts}
`
