import { BaseVariants } from 'models'
import React, { FC, ReactNode } from 'react'
import Button from './Button'
import Spinner from './Spinner'

interface Props {
  confirmVariant?: BaseVariants
  confirmText?: string | ReactNode
  cancelText?: string | ReactNode
  confirmDisabled?: boolean
  loading?: boolean
  submitOnConfirm?: boolean
  onCancel?: () => void
  onConfirm?: () => void
}

const ModalActionButtons: FC<Props> = ({
  onConfirm,
  confirmText,
  cancelText,
  onCancel,
  confirmDisabled,
  submitOnConfirm,
  confirmVariant,
  loading,
}) => {
  return (
    <div className='flex justify-end mt-6 -mx-7 bg-gray-100 rounded-b-md -mb-10 p-3'>
      {onCancel && (
        <Button
          tabIndex={-1}
          className='mr-2'
          variant='light'
          onClick={onCancel}
          type='button'
          disabled={loading}
        >
          {cancelText || 'Avbryt'}
        </Button>
      )}
      {(onConfirm || submitOnConfirm) && (
        <Button
          onClick={onConfirm}
          type={submitOnConfirm ? 'submit' : 'button'}
          variant={confirmVariant || 'primary'}
          disabled={confirmDisabled || loading}
          className={confirmDisabled ? 'opacity-50' : ''}
        >
          {loading && <Spinner size={16} className='mr-2' color='primary' />}
          {confirmText || 'Bekräfta'}
        </Button>
      )}
    </div>
  )
}

export default ModalActionButtons
