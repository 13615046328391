import { gql } from '@apollo/client'

export default gql`
  fragment OrderParts on Order {
    id
    createdAt
    updatedAt
    externalOrderId
    factor
    name
    customer
    Workspace {
      id
    }
  }
`
