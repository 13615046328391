import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../base-components/Button'

const VerifyEmailPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
        <div className='space-y-6'>
          <p className='text-center text-gray-600 text-sm font-medium'>
            {t('VerifyEmailPage.verify_your_email')}
          </p>
          <Button
            className='w-full justify-center'
            variant='primary'
            onClick={() => navigate('/signin')}
          >
            {t('VerifyEmailPage.take_me_to_signin')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmailPage
