import { gql } from '@apollo/client'
import OrderParts from '../fragments/OrderParts'

export const CreateOrderMutation = gql`
  mutation CreateOrder(
    $workspaceId: ID!
    $externalOrderId: Int!
    $factor: Float!
  ) {
    createOrder(
      workspaceId: $workspaceId
      externalOrderId: $externalOrderId
      factor: $factor
    ) {
      id
      ...OrderParts
    }
  }
  ${OrderParts}
`

export const UpdateOrderMutation = gql`
  mutation UpdateOrder($orderId: ID!, $factor: Float!) {
    updateOrder(orderId: $orderId, factor: $factor) {
      id
      ...OrderParts
    }
  }
  ${OrderParts}
`

export const DeleteOrderMutation = gql`
  mutation DeleteOrder($orderId: ID!) {
    deleteOrder(orderId: $orderId) {
      id
      ...OrderParts
    }
  }
  ${OrderParts}
`
