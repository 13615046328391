import { ApolloError, useMutation, useQuery } from '@apollo/client'
import Tooltip from 'base-components/Tooltip'
import CommentFilter from 'components/CommentFilter'
import Comments from 'components/Comments'
import CreateCommentModal from 'components/CreateCommentModal'
import CurrentWorkforceCards from 'components/CurrentWorkforceCards'
import { DateFiltersType } from 'components/DateFilter'
import Filters, { FiltersType } from 'components/Filters'
import MassAttest from 'components/MassAttest'
import Table, { TableColumn, TableRowAction } from 'components/Table'
import { endOfWeek, format, min, startOfWeek } from 'date-fns'
import {
  AttestFilter,
  Orders,
  OrdersVariables,
  UpdateWorkdayMeta,
  UpdateWorkdayMetaVariables,
  WorkdayCommentFilter,
  WorkdayDeviation,
  WorkdayParts,
  WorkdaysVariables,
  Workdays_workdays,
} from 'generatedTypes'
import { UpdateWorkdayMetaMutation } from 'graphql/mutations/WorkdayMeta'
import { OrdersQuery } from 'graphql/queries/Order'
import { WorkdaysQuery } from 'graphql/queries/Workday'
import useQueryParam from 'hooks/useQueryParam'
import { useAuth } from 'providers/AuthProvider'
import { useWorkspace } from 'providers/WorkspaceProvider'
import { FC, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { BiCommentDetail } from 'react-icons/bi'
import { BsCalendarX, BsCheckLg } from 'react-icons/bs'
import { MdOutlineTimerOff } from 'react-icons/md'

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const { t } = useTranslation()
  const formatDeviationInfo = useCallback(
    (workday: WorkdayParts) => {
      return (
        <div>
          {workday.deviation === WorkdayDeviation.MISSING_TIMESTAMP && (
            <div className='font-bold'>{t('Home.missingTimestamps')}</div>
          )}
          {workday.deviation === WorkdayDeviation.CHANGED_SCHEDULE && (
            <div className='font-bold'>{t('Home.changedSchedule')}</div>
          )}
          <div>
            Instämpling: {workday.reportedStartTime ?? t('Home.missing')}
          </div>
          <div>Utstämpling: {workday.reportedEndTime ?? t('Home.missing')}</div>
          <div>
            Schemalagd starttid:{' '}
            {workday.scheduledStartTime ?? t('Home.missing')}
          </div>
          <div>
            Schemalagd sluttid: {workday.scheduledEndTime ?? t('Home.missing')}
          </div>
        </div>
      )
    },
    [t],
  )
  const { me } = useAuth()
  const { selectedWorkspace } = useWorkspace()
  const [filters] = useQueryParam<FiltersType>('filter')
  const [dateFilter] = useQueryParam<DateFiltersType>('dateFilter')

  const orders = useQuery<Orders, OrdersVariables>(OrdersQuery, {
    variables: { workspaceId: selectedWorkspace?.id ?? '' },
    skip: !selectedWorkspace,
  })

  const [updateWorkdayMeta] = useMutation<
    UpdateWorkdayMeta,
    UpdateWorkdayMetaVariables
  >(UpdateWorkdayMetaMutation)

  const [commentWorkday, setCommentWorkday] = useState<WorkdayParts | null>()

  const rowActions = useMemo<TableRowAction<WorkdayParts>[]>(
    () => [
      {
        title: t('Home.createComment'),
        onClick: (workday) => {
          setCommentWorkday(workday)
        },
      },
    ],
    [t],
  )
  const handleUpdateWorkdayMeta = useCallback(
    async (workday: Workdays_workdays, attested: boolean) => {
      const toastId = toast.loading(t('Home.updateWorkday_loading'))
      try {
        if (!selectedWorkspace) throw new Error('No workspace selected')
        if (!me) throw new Error('No user')

        // TODO types must be sorted out in backend
        await updateWorkdayMeta({
          variables: {
            data: {
              workdayMetaId: workday?.WorkdayMeta?.id,
              workdayId: workday.id,
              attested,
              workspaceId: selectedWorkspace.id,
            },
          },
          optimisticResponse: {
            updateWorkdayMeta: {
              ...workday,
              WorkdayMeta: workday.WorkdayMeta
                ? { ...workday.WorkdayMeta, attested }
                : {
                    __typename: 'WorkdayMeta',
                    CommentCount: null,
                    Comments: [],
                    attestedById: me?.id ?? '',
                    AttestedBy: me,
                    createdAt: new Date(),
                    externalWorkdayId: workday.id,
                    id: new Date().getTime().toString(),
                    updatedAt: new Date(),
                    attested,
                  },
            },
          },
        })

        toast.success(t('Home.updateWorkday_success'), { id: toastId })
      } catch (e) {
        let error = e as ApolloError
        console.error(error)
        toast.error(t('Home.updateWorkday_error'), { id: toastId })
      }
    },
    [t, selectedWorkspace, updateWorkdayMeta, me],
  )

  const renderAttested = useCallback(
    (row: Workdays_workdays) => {
      const attested = Boolean(row.WorkdayMeta?.attested)

      return (
        <input
          type='checkbox'
          name='attested'
          id='attested'
          className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
          checked={attested}
          onChange={(e) => handleUpdateWorkdayMeta(row, e.target.checked)}
        />
      )
    },
    [handleUpdateWorkdayMeta],
  )

  const columns = useMemo<TableColumn<Workdays_workdays>[]>(
    () => [
      {
        id: 'name',
        title: t('Home.name'),
      },
      {
        id: 'orderName',
        title: t('Home.orderName'),
        formatter: (orderName: string, row) => (
          <div className='max-w-[210px] truncate'>
            {row.orderId} - {orderName}
          </div>
        ),
      },
      {
        id: 'date',
        title: t('Home.date'),
        formatter: (_, row) => new Date(row.startTime).toLocaleDateString(),
      },
      {
        id: 'startTime',
        title: t('Home.startTime'),
        formatter: (startTime: string) => format(new Date(startTime), 'HH:mm'),
      },
      {
        id: 'endTime',
        title: t('Home.endTime'),
        formatter: (endTime: string) => format(new Date(endTime), 'HH:mm'),
      },
      {
        id: 'overTime',
        title: t('Home.overTime'),
      },
      {
        id: 'deviation',
        title: t('Home.deviation'),
        formatter: (deviation: WorkdayDeviation, workday) => {
          if (deviation === WorkdayDeviation.MISSING_TIMESTAMP) {
            return (
              <Tooltip text={formatDeviationInfo(workday)}>
                <div>
                  <MdOutlineTimerOff className='text-base mx-auto' />
                </div>
              </Tooltip>
            )
          } else if (deviation === WorkdayDeviation.CHANGED_SCHEDULE) {
            return (
              <Tooltip text={formatDeviationInfo(workday)}>
                <div>
                  <BsCalendarX className='text-base mx-auto' />
                </div>
              </Tooltip>
            )
          }
        },
      },
      {
        id: 'comment',
        title: t('Home.comment'),
        formatter: (_, row) =>
          row.WorkdayMeta?.CommentCount ? (
            <Tooltip
              text={row.WorkdayMeta.Comments?.map((comment) => (
                <div>
                  <span className='font-bold'>{`${comment.Creator?.name}`}</span>
                  {`: ${comment.text}`}
                </div>
              ))}
            >
              <div className='flex flex-row gap-1 items-center w-fit'>
                <BiCommentDetail /> {row.WorkdayMeta?.CommentCount}
              </div>
            </Tooltip>
          ) : (
            '-'
          ),
      },
      {
        id: 'customerAttested',
        title: t('Home.customerAttested'),
        formatter: (_, row) => renderAttested(row),
      },
      {
        id: 'isBossConfirmed',
        title: t('Home.attested'),
        formatter: (isBossConfirmed, row) =>
          isBossConfirmed ? <BsCheckLg className='text-green-600' /> : '',
      },
    ],
    [formatDeviationInfo, renderAttested, t],
  )

  const orderIdsParam = useMemo(() => {
    if (!orders.data?.orders) return []
    if (!filters?.orderIds || filters.orderIds.length === 0)
      return orders.data.orders.map((order) => order.externalOrderId)

    return filters.orderIds.map((orderId) => Number(orderId))
  }, [orders, filters])

  const attestFilterParam = useMemo(() => {
    if (!filters?.attested || filters.attested.length === 0)
      return [AttestFilter.ATTESTED, AttestFilter.NOT_ATTESTED]

    const attestedFilter = []
    if (filters?.attested.includes('isAttested'))
      attestedFilter.push(AttestFilter.ATTESTED)
    if (filters?.attested.includes('isNotAttested'))
      attestedFilter.push(AttestFilter.NOT_ATTESTED)
    return attestedFilter
  }, [filters])

  const commentFilterParam = useMemo(() => {
    if (!filters?.comment || filters?.comment.length === 0)
      return [
        WorkdayCommentFilter.WITH_COMMENTS,
        WorkdayCommentFilter.WITHOUT_COMMENTS,
      ]

    const commentFilter = []
    if (filters?.comment.includes('withComments'))
      commentFilter.push(WorkdayCommentFilter.WITH_COMMENTS)
    if (filters?.comment.includes('withoutComments'))
      commentFilter.push(WorkdayCommentFilter.WITHOUT_COMMENTS)
    return commentFilter
  }, [filters])
  const deviationFilterParam: WorkdayDeviation[] = useMemo(
    () =>
      (filters?.deviation as WorkdayDeviation[]) ?? ([] as WorkdayDeviation[]),
    [filters?.deviation],
  )
  const puncedInFilterParam: boolean | undefined = useMemo(
    () =>
      filters?.punchedIn !== undefined
        ? Boolean(filters?.punchedIn)
        : undefined,
    [filters?.punchedIn],
  )
  const dateFilterParam = useMemo(() => {
    const maxDateTo = min([
      endOfWeek(new Date(), { weekStartsOn: 1 }),
      new Date(),
    ])

    return {
      dateFrom: dateFilter
        ? new Date(dateFilter.from).getTime()
        : startOfWeek(new Date(), {
            weekStartsOn: 1,
          }).getTime(),
      dateTo: dateFilter
        ? new Date(dateFilter.to).getTime()
        : maxDateTo.getTime(),
    }
  }, [dateFilter])

  return (
    <>
      <div className='grid grid-cols-1 gap-4 lg:col-span-2 order-2 lg:order-1'>
        <section aria-labelledby='section-1-title'>
          <h3 className='mb-5'>{t('MassAttest.massAttest')}</h3>
          <div className='pb-5'>
            <MassAttest />
          </div>

          <h3 className='mb-5'>{t('Home.currentWorkforce')} (HC)</h3>
          <div className='pb-5'>
            <CurrentWorkforceCards />
          </div>

          <h3 className='mr-8 mb-5 lg:mb-0'>{t('Home.attest_header')}</h3>
          <div className='pb-5 pl-2'>
            <Filters orders={orders} />
          </div>

          <div className='rounded-lg bg-white shadow'>
            <Table<WorkdayParts, WorkdaysVariables>
              query={WorkdaysQuery}
              columns={columns}
              rowActions={rowActions}
              variables={{
                workspaceId: selectedWorkspace?.id ?? '',
                orderIds: orderIdsParam,
                attestFilter: attestFilterParam,
                dateFrom: dateFilterParam.dateFrom,
                dateTo: dateFilterParam.dateTo,
                commentFilter: commentFilterParam,
                deviationFilter: deviationFilterParam,
                punchedInFilter: puncedInFilterParam,
              }}
              skipQuery={
                !orders.data?.orders || !Boolean(selectedWorkspace?.id)
              }
            />
          </div>
        </section>
      </div>

      <div className='grid grid-cols-1 gap-4 order-1 lg:order-2'>
        <div className='flex h-12 justify-between items-center'>
          <h3>{t('Home.comment_header')}</h3>
          <CommentFilter />
        </div>
        <section aria-labelledby='section-2-title'>
          <div className='rounded-lg bg-white overflow-hidden shadow'>
            <div className='p-6'>
              <Comments />
            </div>
          </div>
        </section>
      </div>

      <CreateCommentModal
        open={Boolean(commentWorkday)}
        externalWorkdayId={commentWorkday?.id}
        workspaceId={selectedWorkspace?.id}
        onClose={() => setCommentWorkday(null)}
      />
    </>
  )
}

export default Home
