import { ApolloError, useMutation } from '@apollo/client'
import Modal from 'base-components/Modal'
import ModalActionButtons from 'base-components/ModalActionButtons'
import ModalHeader from 'base-components/ModalHeader'
import { DeleteUser, DeleteUserVariables, UserParts } from 'generatedTypes'
import { DeleteUserMutation } from 'graphql/mutations/User'
import React, { FC } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface Props {
  user: UserParts | null
  open: boolean
  onClose: () => void
}

const DeleteUserModal: FC<Props> = ({ user, open, onClose }) => {
  const { t } = useTranslation()

  const [deleteUser, { loading }] = useMutation<
    DeleteUser,
    DeleteUserVariables
  >(DeleteUserMutation)

  const handleDeleteUser = async () => {
    if (!user) throw new Error('user is undefined')

    const toastId = toast.loading(t('DeleteUserModal.loading'))
    try {
      await deleteUser({
        variables: {
          id: user.id,
        },
        refetchQueries: ['Users'],
      })
      toast.success(t('DeleteUserModal.success'), {
        id: toastId,
      })
      onClose()
    } catch (e) {
      let error = e as ApolloError
      console.error(error)
      toast.error(t('DeleteUserModal.error'), {
        id: toastId,
      })
    }
  }

  if (!user) {
    return <div />
  }

  return (
    <Modal open={open} onClose={onClose} className='w-full max-w-lg'>
      <ModalHeader title={t('DeleteUserModal.title')} onClose={onClose} />
      <p className='mt-4'>
        {`${t('DeleteUserModal.text_name')} `}
        <br />
        <b>{user.name}</b>
        {` ${t('DeleteUserModal.text_email')} `}
        <br />
        <b>{user.email}</b>?
      </p>
      <ModalActionButtons
        loading={loading}
        confirmDisabled={loading}
        confirmText={t('DeleteUserModal.confirm')}
        onCancel={onClose}
        onConfirm={handleDeleteUser}
      />
    </Modal>
  )
}

export default DeleteUserModal
