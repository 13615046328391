import { useMutation } from '@apollo/client'
import Modal from 'base-components/Modal'
import ModalActionButtons from 'base-components/ModalActionButtons'
import ModalHeader from 'base-components/ModalHeader'
import { DeleteOrder, DeleteOrderVariables } from 'generatedTypes'
import { DeleteOrderMutation } from 'graphql/mutations/Order'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { FC, Fragment } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface Props {
  orderId: string | null
  open: boolean
  onClose: () => void
}

const DeleteOrderModal: FC<Props> = ({ orderId, open, onClose }) => {
  const { t } = useTranslation()
  const { selectedWorkspace } = useWorkspace()

  const [deleteOrder, { loading }] = useMutation<
    DeleteOrder,
    DeleteOrderVariables
  >(DeleteOrderMutation, {
    update: (cache) => {
      cache.modify({
        fields: {
          workdays(_, { DELETE }) {
            return DELETE
          },
          orders(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
  })

  const handleDeleteOrder = async () => {
    const toastId = toast.loading(t('DeleteOrderModal.deleteOrder_loading'))

    try {
      if (orderId && selectedWorkspace?.id) {
        await deleteOrder({
          variables: { orderId },
          refetchQueries: ['Orders'],
          awaitRefetchQueries: true,
        })
        toast.success(t('DeleteOrderModal.deleteOrder_success'), {
          id: toastId,
        })
      } else {
        toast.error(t('DeleteOrderModal.deleteOrder_error'), { id: toastId })
      }
      onClose()
    } catch (e) {
      console.error(e)
      toast.error(t('DeleteOrderModal.deleteOrder_error'), {
        id: toastId,
      })
    }
  }

  if (!orderId) {
    return <Fragment />
  }

  return (
    <Modal open={open} onClose={onClose} className='w-full max-w-lg'>
      <ModalHeader title={t('DeleteOrderModal.title')} onClose={onClose} />
      <p className='mt-4'>{t('DeleteOrderModal.deleteOrder_text')}</p>
      <ModalActionButtons
        loading={loading}
        confirmDisabled={loading}
        confirmText={t('DeleteOrderModal.delete')}
        onCancel={onClose}
        onConfirm={handleDeleteOrder}
      />
    </Modal>
  )
}

export default DeleteOrderModal
