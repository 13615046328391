import { useMutation } from '@apollo/client'
import Button from 'base-components/Button'
import CheckboxDropdown from 'base-components/CheckboxDropdown'
import CreateWorkspaceModal from 'components/CreateWorkspaceModal'
import DeleteUserModal from 'components/DeleteUserModal'
import DeleteWorkspaceModal from 'components/DeleteWorkspaceModal'
import Table, { TableColumn, TableRowAction } from 'components/Table'
import {
  UpdateUserPrivilegeMutation,
  UpdateUserWorkspaceMutation,
} from 'graphql/mutations/User'
import { UsersQuery } from 'graphql/queries/User'
import { WorkspacesQuery } from 'graphql/queries/Workspace'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  SortOrder,
  UpdateUserPrivilege,
  UpdateUserPrivilegeVariables,
  UpdateUserWorkspace,
  UpdateUserWorkspaceVariables,
  UserParts,
  UsersParts,
  WorkspaceParts,
} from '../generatedTypes'

const UserInvitesPage: React.FC = () => {
  const { t } = useTranslation()
  const { workspaces } = useWorkspace()
  const [deleteUser, setDeleteUser] = useState<UserParts | null>(null)
  const [createWorkspace, setCreateWorkspace] = useState(false)
  const [deleteWorkspace, setDeleteWorkspace] = useState<WorkspaceParts | null>(
    null,
  )
  const [updateUserWorkspace] = useMutation<
    UpdateUserWorkspace,
    UpdateUserWorkspaceVariables
  >(UpdateUserWorkspaceMutation)

  const handleChangeWorkplace = useCallback(
    (user: UserParts) =>
      async (e: React.ChangeEvent<HTMLInputElement>, workspaceName: string) => {
        const { id: workspaceId, checked } = e.target

        await toast.promise(
          updateUserWorkspace({
            variables: {
              data: {
                userId: user.id,
                connect: checked,
                workspaceId,
              },
            },
            refetchQueries: ['Users'],
          }),
          {
            loading: t('CheckboxDropdown.loading'),
            success: (
              <p>
                <span className='font-semibold'>{user.name}</span>
                {checked
                  ? ` ${t('CheckboxDropdown.connected')} `
                  : ` ${t('CheckboxDropdown.disconnected')} `}
                <br />
                <span className='font-semibold'>{workspaceName}</span>
              </p>
            ),
            error: t('CheckboxDropdown.error'),
          },
        )
      },
    [t, updateUserWorkspace],
  )
  const privileges = useMemo(
    () => [
      { value: 'ADMIN', label: t('UsersPage.admin') },
      { value: 'REPORT', label: t('UsersPage.attestreport') },
    ],
    [t],
  )
  const [updateUserPrivilege] = useMutation<
    UpdateUserPrivilege,
    UpdateUserPrivilegeVariables
  >(UpdateUserPrivilegeMutation)
  const handleChangePrivilege = useCallback(
    (user: UserParts) => async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, id } = e.target
      let data = {}

      if (id === 'ADMIN') {
        data = {
          isAdmin: checked,
        }
      } else if (id === 'REPORT') {
        data = {
          isElevatedUser: checked,
        }
      }
      await toast.promise(
        updateUserPrivilege({
          variables: {
            data: {
              userId: user.id,
              ...data,
            },
          },
          refetchQueries: ['Users'],
        }),
        {
          loading: t('UsersPage.loadingChangePrivilege'),
          success: t('UsersPage.successChangePrivilege'),
          error: t('UsersPage.errorChangePrivilege'),
        },
      )
    },
    [t, updateUserPrivilege],
  )

  const userColumns = useMemo<TableColumn<UsersParts>[]>(() => {
    return [
      { id: 'name', title: t('UsersPage.name') },
      { id: 'email', title: t('UsersPage.email') },
      {
        id: 'workspaces',
        title: t('UsersPage.workspaces'),
        className: () => '!overflow-visible',
        formatter: (_, user) => (
          <CheckboxDropdown
            label={t('UsersPage.workspace_label')}
            onChange={handleChangeWorkplace(user)}
            options={workspaces.map((workspace) => ({
              value: workspace.id,
              label: workspace.name,
              checked: Boolean(
                user.Workspaces?.find(
                  (userWorkspace) => userWorkspace.id === workspace.id,
                ),
              ),
            }))}
          />
        ),
      },
      {
        id: 'privileges',
        title: t('UsersPage.privileges'),
        className: () => '!overflow-visible',
        formatter: (_, user) =>
          user.isSuperAdmin ? (
            'Super admin'
          ) : (
            <CheckboxDropdown
              onChange={handleChangePrivilege(user)}
              label={t('UsersPage.privileges')}
              options={privileges.map((privilege) => ({
                value: privilege.value,
                label: privilege.label,
                checked: Boolean(
                  (user.isAdmin && privilege.value === 'ADMIN') ||
                    (user.isElevatedUser && privilege.value === 'REPORT'),
                ),
              }))}
            />
          ),
      },
    ]
  }, [handleChangePrivilege, handleChangeWorkplace, privileges, t, workspaces])

  const userRowActions = useMemo<TableRowAction<UsersParts>[]>(
    () => [
      {
        title: t('UsersPage.delete'),
        onClick: (user) => setDeleteUser(user),
        renderCondition: (user) => !(user.isAdmin || user.isSuperAdmin),
      },
    ],
    [t],
  )

  const workspaceColumns = useMemo<TableColumn<WorkspaceParts>[]>(() => {
    return [{ id: 'name', title: t('UsersPage.name') }]
  }, [t])

  const workspaceRowActions = useMemo<TableRowAction<WorkspaceParts>[]>(
    () => [
      {
        title: t('UsersPage.delete'),
        onClick: (workspace) => setDeleteWorkspace(workspace),
      },
    ],
    [t],
  )

  return (
    <>
      <div
        className='grid grid-cols-1 gap-4 lg:col-span-2 order-2 lg:order-1'
        data-testid='users-route'
      >
        <section aria-labelledby='section-1-title'>
          <div className='rounded-lg bg-white overflow-hidden shadow'>
            <div className='p-6'>
              <h2 className='mb-4'>{t('UsersPage.users_header')}</h2>

              <Table<UsersParts, undefined>
                columns={userColumns}
                query={UsersQuery}
                rowActions={userRowActions}
                initialOrderBy={{ name: SortOrder.desc }}
              />
            </div>
          </div>
        </section>
      </div>

      <div className='grid grid-cols-1 gap-4 lg:col-span-1 order-1 lg:order-2'>
        <section aria-labelledby='section-2-title'>
          <div className='rounded-lg bg-white overflow-hidden shadow'>
            <div className='p-6'>
              <div className='flex flex-row justify-between items-center mb-4'>
                <h2 className=''>{t('UsersPage.workspaces_header')}</h2>
                <Button
                  data-testid='create-workspace-button'
                  className='hidden md:block'
                  onClick={() => setCreateWorkspace(true)}
                >
                  {t('UsersPage.add_workspace')}
                </Button>
              </div>
              <Button
                className='block md:hidden mb-4'
                onClick={() => setCreateWorkspace(true)}
              >
                {t('UsersPage.add_workspace')}
              </Button>
              <Table<WorkspaceParts, undefined>
                columns={workspaceColumns}
                query={WorkspacesQuery}
                rowActions={workspaceRowActions}
                initialOrderBy={{ name: SortOrder.desc }}
              />
            </div>
          </div>
        </section>
      </div>

      <div className='order-3'>
        <DeleteWorkspaceModal
          open={Boolean(deleteWorkspace)}
          workspace={deleteWorkspace}
          onClose={() => setDeleteWorkspace(null)}
        />
        <CreateWorkspaceModal
          open={createWorkspace}
          onClose={() => setCreateWorkspace(false)}
        />
        <DeleteUserModal
          open={Boolean(deleteUser)}
          user={deleteUser}
          onClose={() => setDeleteUser(null)}
        />
      </div>
    </>
  )
}

export default UserInvitesPage
