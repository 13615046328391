import Button from 'base-components/Button'

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MassAttestModal from './MassAttestModal'

interface MassAttestProps {}

const MassAttest: FC<MassAttestProps> = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {t('MassAttest.massAttestAction')}
      </Button>

      <MassAttestModal open={open} onClose={handleClose} />
    </>
  )
}

export default MassAttest
