import { useMutation, useQuery } from '@apollo/client'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import SignUpWithEmail from '../components/SignUpWithEmail'
import SignUpWithMicrosoftButton from '../components/SignUpWithMicrosoftButton'
import {
  CreateUser,
  CreateUserVariables,
  UserInvite,
  UserInviteVariables,
} from '../generatedTypes'
import { CreateUserMutation } from '../graphql/mutations/User'
import { UserInviteQuery } from '../graphql/queries/UserInvite'
import LoadingPage from './LoadingPage'

const SignUpPage = () => {
  const { inviteId } = useParams()
  const [signupType, setSignupType] = useState<
    'microsoft' | 'email' | 'notSelected'
  >('notSelected')
  const [signingUp, setSigningUp] = useState(false)
  const [createUser, { loading }] = useMutation<
    CreateUser,
    CreateUserVariables
  >(CreateUserMutation)
  const { t } = useTranslation()

  const userInviteResponse = useQuery<UserInvite, UserInviteVariables>(
    UserInviteQuery,
    { variables: { id: inviteId ?? '' } },
  )
  const userInvite = useMemo(
    () => userInviteResponse?.data?.userInvite,
    [userInviteResponse],
  )

  return (
    <div>
      <h1 className='text-center text-white'>Inbjudan</h1>
      {userInviteResponse.loading || signingUp || loading ? (
        <LoadingPage />
      ) : userInvite ? (
        new Date(userInvite.expiresAt) > new Date() ? (
          <Window>
            {signupType === 'notSelected' && (
              <button
                onClick={() => setSignupType('email')}
                className='w-full flex justify-center cursor-pointer py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {t('SignUpPage.sign_up_with_email')}
              </button>
            )}
            {signupType === 'email' && (
              <SignUpWithEmail
                setSigningUp={setSigningUp}
                userInvite={userInvite}
              />
            )}
            {signupType === 'notSelected' && (
              <div className='mt-6'>
                <SplitLine />

                <div className='mt-6 grid grid-cols-1 gap-3'>
                  <SignUpWithMicrosoftButton
                    createUser={createUser}
                    setSigningUp={setSigningUp}
                    userInvite={userInvite}
                  />
                </div>
              </div>
            )}
          </Window>
        ) : (
          <Window>{t('SignUpPage.invite_expired')}</Window>
        )
      ) : (
        <Window>{t('SignUpPage.invite_not_found')}</Window>
      )}
    </div>
  )
}

const Window: FC = ({ children }) => {
  return (
    <div className='mt-4 xl:mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
        {children}
      </div>
    </div>
  )
}

const SplitLine: FC = () => {
  const { t } = useTranslation()

  return (
    <div className='relative'>
      <div className='absolute inset-0 flex items-center'>
        <div className='w-full border-t border-gray-300' />
      </div>
      <div className='relative flex justify-center text-sm'>
        <span className='px-2 bg-white text-gray-500'>
          {t('SignUpPage.or_continue_with')}
        </span>
      </div>
    </div>
  )
}

export default SignUpPage
