import React, { FC, useEffect } from 'react'

interface ErrorPageProps {
  error: Error
}

const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <div>
      <h1>Error</h1>
    </div>
  )
}

export default ErrorPage
