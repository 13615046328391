import useOpenState from 'hooks/useOpenState'
import React, { useCallback } from 'react'
import { MdMoreHoriz } from 'react-icons/md'
import { useLayer } from 'react-laag'
import PopoverCard from './PopoverCard'
import { TableRowAction } from './Table'

interface Props<T> {
  actions: TableRowAction<T>[]
  row: T
}

function TableRowActionButton<T extends { id: string | number }>({
  actions,
  row,
}: Props<T>) {
  const [menuOpen, openMenu, closeMenu] = useOpenState()
  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen: menuOpen,
    placement: 'bottom-end',
    triggerOffset: 0,
    containerOffset: 0,
    onOutsideClick: closeMenu,
  })

  const handleOnClick = useCallback(
    (action) => () => {
      action.onClick(row)
      closeMenu()
    },
    [closeMenu, row],
  )
  return (
    <>
      <button
        {...triggerProps}
        onClick={openMenu}
        className='w-10 h-10 rounded-full flex-center hover:bg-gray-100 text-gray-500'
      >
        <MdMoreHoriz size={18} />
      </button>
      <PopoverCard
        renderLayer={renderLayer}
        layerProps={layerProps}
        open={menuOpen}
        arrowProps={arrowProps}
      >
        <div className='w-[200px] flex flex-col divide-y divide-gray-100'>
          {actions.map((action, index) => {
            const disabled = !(
              !action?.renderCondition || action.renderCondition(row)
            )
            return (
              <button
                onClick={handleOnClick(action)}
                key={index}
                className={`flex items-center px-3 py-3 text-sm  ${
                  disabled ? 'text-gray-400' : 'text-gray-800 hover:bg-gray-100'
                }`}
                disabled={disabled}
              >
                {action.icon}
                <span className='ml-2'>{action.title}</span>
              </button>
            )
          })}
        </div>
      </PopoverCard>
    </>
  )
}

export default TableRowActionButton
