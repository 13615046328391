import { FirebaseError } from 'firebase/app'
import { getAuth, OAuthProvider, signInWithPopup } from 'firebase/auth'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { IoLogoMicrosoft } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const SignInWithMicrosoftButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const signInWithMicrosoft = async () => {
    const provider = new OAuthProvider('microsoft.com')
    const auth = getAuth()

    try {
      const result = await signInWithPopup(auth, provider)
      OAuthProvider.credentialFromResult(result)

      navigate('/')
    } catch (e) {
      const error = e as FirebaseError
      const code = error?.code ?? 'unknown'

      const messages: { [key: string]: string } = {
        'auth/account-exists-with-different-credential': t(
          'SignInWithMicrosoftButton.auth/account-exists-with-different-credential',
        ),
        'auth/cancelled-popup-request': t(
          'SignInWithMicrosoftButton.auth/cancelled-popup-request',
        ),
        'auth/popup-blocked': t('SignInWithMicrosoftButton.auth/popup-blocked'),
        'auth/popup-closed-by-user': t(
          'SignInWithMicrosoftButton.auth/popup-closed-by-user',
        ),
        'auth/unauthorized-domain': t(
          'SignInWithMicrosoftButton.auth/unauthorized-domain',
        ),
      }

      toast.error(
        messages[code] ?? t('SignInWithMicrosoftButton.unknown-error'),
        {
          duration: 5000,
        },
      )
    }
  }

  return (
    <button onClick={signInWithMicrosoft} className='cursor-pointer'>
      <span className='w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'>
        <span className='sr-only'>
          {t('SignInWithMicrosoftButton.sign_in_with_microsoft')}
        </span>
        <IoLogoMicrosoft className='w-5 h-5' />
      </span>
    </button>
  )
}

export default SignInWithMicrosoftButton
