import { gql } from '@apollo/client'
import WorkdayParts from 'graphql/fragments/WorkdayParts'
import WorkdayWithCommentsParts from 'graphql/fragments/WorkdayWithCommentsParts'

export const WorkdaysQuery = gql`
  query Workdays(
    $orderIds: [Int!]!
    $workspaceId: ID!
    $dateFrom: Date!
    $dateTo: Date!
    $limit: Int
    $cursor: String
    $attestFilter: [AttestFilter!]!
    $commentFilter: [WorkdayCommentFilter!]!
    $deviationFilter: [WorkdayDeviation!]!
    $punchedInFilter: Boolean
  ) {
    workdays(
      orderIds: $orderIds
      limit: $limit
      cursor: $cursor
      dateFrom: $dateFrom
      dateTo: $dateTo
      workspaceId: $workspaceId
      attestFilter: $attestFilter
      commentFilter: $commentFilter
      deviationFilter: $deviationFilter
      punchedInFilter: $punchedInFilter
    ) {
      id
      ...WorkdayParts
    }
  }
  ${WorkdayParts}
`

export const WorkdaysWithCommentsQuery = gql`
  query WorkdaysWithComments(
    $data: WorkdaysWithCommentsInput!
    $limit: Int
    $cursor: String
    $commentFilter: [CommentFilter!]!
  ) {
    workdaysWithComments(
      data: $data
      limit: $limit
      cursor: $cursor
      commentFilter: $commentFilter
    ) {
      id
      ...WorkdayWithCommentsParts
    }
  }
  ${WorkdayWithCommentsParts}
`
