import { AnimatePresence, motion } from 'framer-motion'
import React, { cloneElement, FC, ReactNode } from 'react'
import { Arrow, useHover, useLayer } from 'react-laag'

interface Props {
  text: string | number | ReactNode
}

const Tooltip: FC<Props> = ({ children, text }) => {
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 })

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: 'top-center',
    triggerOffset: 8,
  })

  let trigger
  if (isReactText(children)) {
    trigger = (
      <span className='tooltip-text-wrapper' {...triggerProps} {...hoverProps}>
        {children}
      </span>
    )
  } else {
    trigger = cloneElement(children as any, {
      ...triggerProps,
      ...hoverProps,
    })
  }

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <motion.div
              className='text-xs rounded-lg p-3 bg-gray-900 backdrop-filter backdrop-blur-[7px] bg-opacity-60 text-white border-0'
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              {text}
              <Arrow
                {...arrowProps}
                size={6}
                roundness={0}
                backgroundColor='#111827'
                className='opacity-60'
              />
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </>
  )
}

function isReactText(children: ReactNode) {
  return ['string', 'number'].includes(typeof children)
}

export default Tooltip
