import { useMutation } from '@apollo/client'
import Modal from 'base-components/Modal'
import ModalActionButtons from 'base-components/ModalActionButtons'
import ModalHeader from 'base-components/ModalHeader'
import Spinner from 'base-components/Spinner'
import { addDays, isAfter, isSameDay } from 'date-fns'
import {
  MassAttestWorkspace,
  MassAttestWorkspaceVariables,
} from 'generatedTypes'
import { MassAttestWorkspaceMutation } from 'graphql/mutations/Workspace'
import { WorkdaysQuery } from 'graphql/queries/Workday'
import { useWorkspace } from 'providers/WorkspaceProvider'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { getDayFormatter } from 'utils/dateUtils'

type FormData = {
  from: Date
  to: Date
  noDeviation: boolean
  noComments: boolean
}

interface Props {
  open: boolean
  onClose: () => void
}

const CreateCommentModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, watch } = useForm<FormData>()
  const { selectedWorkspace } = useWorkspace()

  const [massAttest, { loading }] = useMutation<
    MassAttestWorkspace,
    MassAttestWorkspaceVariables
  >(MassAttestWorkspaceMutation, {
    refetchQueries: [WorkdaysQuery],
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const handleCreateComment: SubmitHandler<FormData> = async (data) => {
    const toastId = toast.loading(t('MassAttest.loading'))
    try {
      if (!selectedWorkspace?.id) {
        throw new Error('No workspaceId or externalWorkdayId')
      }

      await massAttest({
        variables: {
          data: {
            workspaceId: selectedWorkspace.id,
            fromDate: new Date(data.from).toUTCString(),
            toDate: new Date(data.to).toUTCString(),
            noDeviation: data.noDeviation,
            noComments: data.noComments,
          },
        },
      })

      toast.success(t('MassAttest.success'), {
        id: toastId,
      })

      reset()
      onClose()
    } catch (e) {
      console.error(e)
      toast.error(t('MassAttest.error'), {
        id: toastId,
      })
    }
  }

  return (
    <Modal open={open} onClose={onClose} className='w-full max-w-lg'>
      <ModalHeader
        title={`${t('MassAttest.massAttest')}: ${selectedWorkspace?.name}`}
        onClose={onClose}
      />
      <form onSubmit={handleSubmit(handleCreateComment)}>
        {!loading && (
          <div className='space-y-6 py-2'>
            <p className='text-sm text-orange-400'>
              {t('MassAttest.description')}
            </p>

            <div className='flex space-x-5'>
              <label htmlFor='fromModal' className='self-center w-8 text-sm'>
                {t('MassAttest.from')}
              </label>
              <input
                id='fromModal'
                type='date'
                {...register('from')}
                max={getDayFormatter(new Date())}
                className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block pr-2 sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='flex space-x-5'>
              <label htmlFor='toModal' className='self-center w-8 text-sm'>
                {t('MassAttest.to')}
              </label>
              <input
                id='toModal'
                type='date'
                {...register('to', {
                  validate: (value) => {
                    const from = watch('from')
                    if (!from) {
                      return true
                    }
                    return (
                      isAfter(new Date(value), new Date(from)) ||
                      isSameDay(new Date(value), new Date(from))
                    )
                  },
                })}
                min={getDayFormatter(new Date(watch('from')))}
                max={
                  isAfter(new Date(), addDays(new Date(watch('from')), 7))
                    ? getDayFormatter(addDays(new Date(watch('from')), 7))
                    : getDayFormatter(new Date())
                }
                className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block pr-2 sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='space-x-4'>
              <input
                id='noDeviation'
                type='checkbox'
                defaultChecked={true}
                {...register('noDeviation')}
                className='rounded border-gray-400 cursor-pointer'
              />
              <label
                htmlFor='noDeviation'
                className='self-center w-8 text-sm cursor-pointer'
              >
                {t('MassAttest.noDeviation')}
              </label>
            </div>

            <div className='space-x-4'>
              <input
                id='noComments'
                type='checkbox'
                defaultChecked={true}
                {...register('noComments')}
                className='rounded border-gray-400 cursor-pointer'
              />
              <label
                htmlFor='noComments'
                className='self-center w-8 text-sm cursor-pointer'
              >
                {t('MassAttest.noComments')}
              </label>
            </div>
          </div>
        )}

        {loading && <Spinner />}

        <ModalActionButtons
          loading={loading}
          confirmDisabled={loading || !watch('to') || !watch('from')}
          cancelText={t('MassAttest.cancel')}
          confirmText={t('MassAttest.confirm')}
          onCancel={onClose}
          submitOnConfirm
        />
      </form>
    </Modal>
  )
}

export default CreateCommentModal
