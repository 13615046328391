import { ApolloError, useMutation } from '@apollo/client'
import Modal from 'base-components/Modal'
import ModalActionButtons from 'base-components/ModalActionButtons'
import ModalHeader from 'base-components/ModalHeader'
import { CreateWorkspace, CreateWorkspaceVariables } from 'generatedTypes'
import { CreateWorkspaceMutation } from 'graphql/mutations/Workspace'
import React, { FC, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onClose: () => void
}

const CreateWorkspaceModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const nameRef = useRef<HTMLInputElement | null>(null)
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm<{ name: string }>()
  const { ref, ...rest } = register('name', { required: true })

  const [createWorkspace, { loading }] = useMutation<
    CreateWorkspace,
    CreateWorkspaceVariables
  >(CreateWorkspaceMutation)

  const handleClose = () => {
    onClose()
    reset()
  }

  useEffect(() => {
    if (open) nameRef.current?.focus()
  }, [open])

  const handleCreateWorkspace = async (name: string) => {
    const toastId = toast.loading(t('CreateWorkspaceModal.loading'))

    try {
      await createWorkspace({
        variables: {
          name,
        },
        refetchQueries: ['Workspaces'],
      })
      toast.success(t('CreateWorkspaceModal.success'), {
        id: toastId,
      })
      handleClose()
    } catch (e) {
      let error = e as ApolloError
      console.error(error)
      toast.error(t('CreateWorkspaceModal.error'), { id: toastId })
    }
  }

  return (
    <Modal
      dataTestId='create-workspace-dialog'
      open={open}
      onClose={handleClose}
      className='w-full max-w-lg'
    >
      <form onSubmit={handleSubmit(({ name }) => handleCreateWorkspace(name))}>
        <ModalHeader
          title={t('CreateWorkspaceModal.title')}
          onClose={onClose}
        />
        <p className='mt-4'>
          {`${t('CreateWorkspaceModal.text')}`}
          <input
            {...rest}
            ref={(e) => {
              ref(e)
              nameRef.current = e
            }}
            className='mt-4 border border-gray-300 rounded-lg p-2 w-full'
          />
          {errors.name && (
            <span className='my-3 text-red-500'>This field is required</span>
          )}
        </p>
        <ModalActionButtons
          loading={loading}
          confirmDisabled={loading}
          submitOnConfirm
          confirmText={t('CreateWorkspaceModal.confirm')}
          onCancel={onClose}
        />
      </form>
    </Modal>
  )
}

export default CreateWorkspaceModal
