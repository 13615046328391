import React, { FC } from 'react'
import { IoClose } from 'react-icons/io5'

interface Props {
  title: string
  description?: string
  onClose?: () => void
}

const ModalHeader: FC<Props> = ({ title, description, onClose }) => {
  return (
    <div>
      <div className='flex justify-between items-center'>
        <h3>{title}</h3>
        {onClose && (
          <button
            onClick={onClose}
            className='w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center focus:outline-none focus:shadow-outline-blue'
          >
            <IoClose />
          </button>
        )}
      </div>
      {description && <p className='mt-4'>{description}</p>}
    </div>
  )
}

export default ModalHeader
